import { useContext, useEffect } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ShoppingBagIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'

import { CartContext } from '@/Contexts/CartContext'
import HeaderLayout from '@/Layout/Header/Header'
import Footer from '@/Components/Footer/Footer'
import LogoHandler from '@/Helpers/LogoHandler'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useSessionStore } from '@/Hooks/useSessionStore'

export default function Account() {
  const { goBack, goHome } = useHistoryStore()
  const { sessionInfo, setSessionInfo } = useSessionStore()

  const { layoutVars } = useContext(CartContext)

  useEffect(() => {
    if (!sessionInfo || Object.keys(sessionInfo).length === 0) {
      setSessionInfo()
    }
  }, [sessionInfo, setSessionInfo])

  return (
    <>
      <HeaderLayout columns={3}>
        <div>
          <div
            className="hidden lg:flex items-start justify-start w-full max-h-11 max-w-24 lg:max-w-max lg:max-h-40 cursor-pointer"
            onClick={goHome}
          >
            <LogoHandler />
          </div>
          <div className="lg:hidden">
            <ChevronLeftIcon
              className="h-6 w-6 text-stone-700 dark:text-stone-200"
              onClick={goBack}
            />
          </div>
        </div>
        <h5 className="w-full flex-1 line-clamp-1 text-base leading-tight font-semibold text-stone-700 dark:text-stone-200">
          Conta
        </h5>
        <div />
      </HeaderLayout>
      <div className="content h-full pt-16 pb-36 overflow-y-auto lg:container lg:mx-auto">
        <div className="mt-3 mb-14 grid gap-6 grid-cols-1 px-4">
          {sessionInfo?.nmCustomer && (
            <div className="w-full px-4 py-3 bg-stone-200 dark:bg-stone-800 rounded-xl justify-start items-center gap-1 inline-flex">
              <div className="flex-col justify-start items-start gap-1 inline-flex">
                <div className="text-brand-600 dark:text-brand-400 text-base font-semibold leading-normal">
                  {sessionInfo?.nmCustomer}
                </div>
                <div className="justify-start items-start gap-1 inline-flex text-brand-900 dark:text-brand-100 text-sm font-normal leading-tight">
                  <div>CNPJ:</div>
                  <div>{sessionInfo?.nrNationalRegistryLabel}</div>
                </div>
              </div>
            </div>
          )}

          {layoutVars.account && layoutVars.account.orders && (
            <div className="w-full h-12 py-3 border-b border-stone-300 dark:border-stone-700 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                <ShoppingBagIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                <div className="grow shrink basis-0 text-stone-700 dark:text-stone-300 text-base font-medium leading-normal">
                  Meus pedidos
                </div>
              </div>
              <div className="justify-start items-center gap-2 flex">
                <div className="w-6 h-6 justify-center items-center flex">
                  <ChevronRightIcon className="h-6 w-6 text-stone-600 dark:text-stone-400" />
                </div>
              </div>
            </div>
          )}

          {layoutVars.account && layoutVars.account.addresses && (
            <div className="w-full h-12 py-3 border-b border-stone-300 dark:border-stone-700 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                <MapPinIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                <div className="grow shrink basis-0 text-stone-700 dark:text-stone-300 text-base font-medium leading-normal">
                  Meus endereços
                </div>
              </div>
              <div className="justify-start items-center gap-2 flex">
                <div className="w-6 h-6 justify-center items-center flex">
                  <ChevronRightIcon className="h-6 w-6 text-stone-600 dark:text-stone-400" />
                </div>
              </div>
            </div>
          )}

          {layoutVars.account && layoutVars.account.help && (
            <div className="w-full h-12 py-3 border-b border-stone-300 dark:border-stone-700 justify-start items-center gap-2 inline-flex">
              <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                <QuestionMarkCircleIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                <div className="grow shrink basis-0 text-stone-700 dark:text-stone-300 text-base font-medium leading-normal">
                  Ajuda
                </div>
              </div>
              <div className="justify-start items-center gap-2 flex">
                <div className="w-6 h-6 justify-center items-center flex">
                  <ChevronRightIcon className="h-6 w-6 text-stone-600 dark:text-stone-400" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}
