import { useContext, useState } from 'react'
import { ICart } from '@/Interfaces/Cart'
import { ImagePlacer } from '@/Helpers/Image'
import { XMarkIcon, CubeIcon } from '@heroicons/react/24/outline'
import { CartContext } from '@/Contexts/CartContext'
import { uniqueId } from 'lodash'
import ItemFlag from '@/Components/ItemFlag/ItemFlag'
import QuickAddLayout from '@/Layout/QuickAdd/QuickAdd'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useCartStore } from '@/Hooks/useCartStore'
import ConfirmDialog from '@/Helpers/dialog'
import TagsLayout from '@/Layout/Tags/Tags'

type CartItemsProps = {
  item: ICart
}

export default function CartItems(props: CartItemsProps) {
  const { item } = props
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { setDetailedItem, layoutVars } = useContext(CartContext)

  const suggestionObject =
    item.attributes && item.attributes.suggestion
      ? item.attributes.suggestion
      : {}

  const { addHistory } = useHistoryStore()
  const { removeFromCart } = useCartStore()

  const handleDelete = (item: ICart) => {
    if (!item.id) return
    item.quantity = 0
    removeFromCart(item)
    return
  }

  const attributesArray = item.attributes
    ? Object.keys(item.attributes).map((key) => {
        return { type: key, ...item.attributes[key] }
      })
    : []

  const attributes = () => {
    if (!item.attributes) return

    return attributesArray.map((attribute) => {
      return (
        <ItemFlag
          key={attribute.type}
          type={attribute.type}
          name={attribute.name}
          description={attribute.description}
          valueLabel={attribute.valueLabel}
          exp={attribute.exp}
          iat={attribute.iat}
        />
      )
    })
  }

  const handleConfirm = () => {
    removeFromCart(item)
    setIsDialogOpen(false)
  }

  return (
    <>
      <div
        className={`w-full min-h-32 p-3 bg-stone-50 dark:bg-stone-800 rounded-lg shadow justify-start items-start gap-4 inline-flex overflow-hidden relative`}
      >
        {layoutVars.catalog &&
          layoutVars.catalog.suggestion &&
          Object.keys(suggestionObject).length > 0 && (
            <div className="absolute left-2 top-2">
              <TagsLayout
                label={suggestionObject.suggestedReason}
                type="icon"
                icon={suggestionObject.suggestedType}
              />
            </div>
          )}
        <div className="flex-col justify-between items-center gap-3 inline-flex w-1/4 h-full">
          <div className="min-w-full w-full h-2/3 [&>img]:h-auto [&>img]:max-h-18 justify-center flex">
            <ImagePlacer image={item.image} name={item.name} />
          </div>
          {item.picking && (
            <div className="justify-center items-center gap-1 inline-flex">
              <CubeIcon className="h-5 w-5 text-stone-500" />
              <div className="text-stone-500 text-xs font-normal leading-none">
                {item.picking}
              </div>
            </div>
          )}
        </div>
        <div className="flex-col justify-between items-start gap-2 inline-flex w-3/4 h-full">
          <div className="self-stretch justify-between items-start gap-2 inline-flex align-text-top">
            <div className="text-stone-700 dark:text-stone-300 text-xs font-medium leading-none line-clamp-2 py-0.5">
              {item.name}
            </div>
            <button
              className="w-6 h-6 relative"
              onClick={() => setIsDialogOpen(true)}
            >
              <XMarkIcon className="w-5 h-5 text-stone-500 top-0 right-0 absolute" />
            </button>
            <ConfirmDialog
              key={uniqueId()}
              title="Remover item"
              message="Tem certeza que deseja remover o item do carrinho?"
              confirmText="Remover"
              onConfirm={handleConfirm}
              cancelText="Cancelar"
              onCancel={() => setIsDialogOpen(false)}
              open={isDialogOpen}
              onOpen={() => null}
            />
          </div>
          <div className="justify-start items-center inline-flex">
            <div className="flex-col justify-start items-start gap-1 inline-flex text-stone-600 dark:text-stone-400 text-xs font-normal leading-none">
              {layoutVars.products && layoutVars.products.ean && (
                <div>EAN: {item.cdEan}</div>
              )}
              {layoutVars.products && layoutVars.products.sku && (
                <div>SKU: {item.cdSku}</div>
              )}
              {layoutVars.cart &&
                layoutVars.cart.itemWeight &&
                item.grossWeight && (
                  <div>Peso bruto: {item.grossWeight?.toFixed(2)}kg</div>
                )}
            </div>
          </div>
          {item.error ? (
            <div className="w-full justify-between items-center gap-2 inline-flex">
              <div className="w-full text-red-700 dark:text-red-300 text-sm font-medium leading-tight">
                Produto indisponível
              </div>
            </div>
          ) : (
            <div className="w-full justify-between items-center gap-2 inline-flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-center items-center gap-1 inline-flex">
                {layoutVars.cart.price && (
                  <div className="self-stretch text-brand-700 dark:text-brand-300 text-sm font-semibold leading-tight">
                    <div>{item.priceLabel}</div>
                  </div>
                )}
              </div>
              <div className="flex-1 grow shrink w-3/5 flex justify-end">
                <QuickAddLayout item={item} type={'cart'} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
