import React from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

type SuggestionObject = {
  suggestedReason: string;
  suggestedQty: number;
}

type SuggestionDetailsProps = {
  suggestionObject: SuggestionObject;
  quantity: number;
  setQuantity: (value: number) => void;
}

const SuggestionDetails: React.FC<SuggestionDetailsProps> = React.memo((props) => {
  const { suggestionObject, quantity, setQuantity } = props;

  return (
    <div className="w-full">
      <div className="w-full px-3 py-2 bg-stone-200 dark:bg-stone-800 rounded-lg justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 text-brand-900 dark:text-brand-100 text-sm font-medium leading-tight">
          <p>{suggestionObject.suggestedReason}</p>
          <p>Quantidade sugerida: {suggestionObject.suggestedQty}</p>
        </div>
        <div className="flex-0 h-5 px-1 py-0.5 bg-stone-100 dark:bg-stone-900 rounded justify-center items-center gap-0.5 flex">
          {
            (quantity >= suggestionObject.suggestedQty)
            ? <div className="text-green-600 dark:text-green-400 text-xs font-semibold leading-none inline-flex items-center">
                <CheckIcon className="h-3 w-3 mr-1" />
                Ativado
              </div>
            : <div
              className="text-brand-600 dark:text-brand-400 text-xs font-semibold leading-none"
              onClick={() => setQuantity(suggestionObject.suggestedQty)}
            >
              Adicionar
            </div>
          }
        </div>
      </div>
    </div>
  );
});

export default SuggestionDetails;