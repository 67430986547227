import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { ICart, ICartResponse } from '@/Interfaces/Cart'
import { LoadingContext } from '@/Contexts/LoadingContext'
import { uniqueId } from 'lodash'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useTokenStore } from '@/Hooks/useTokenStore'
import { useHeaderStore } from '@/Hooks/useHeaderStore'
import { useCartStore } from '@/Hooks/useCartStore'

export const CartContext = createContext<any>(undefined)

interface ICartRequest {
  method: string
  headers?: any
  body?: string
}

enum CartMethods {
  put = 'PUT',
  get = 'GET',
  post = 'POST',
  delete = 'DELETE',
}

enum PlaceOrderMessages {
  PLACE_ORDER_STOCK_INSUFFICIENT = 'Não foi possível realizar a compra. Alguns produtos não estão disponíveis no estoque.',
}

enum CartErrorMessages {
  PLACE_ORDER_STOCK_INSUFFICIENT = 'Estoque insuficiente. (Disponível: {0})',
}

interface IAlerts {
  id: string
  type: string
  message: string
  readed: boolean
  logged: boolean
}

const blankProduct: ICart = {
  id: '',
  cdSku: '',
  cdEan: '',
  name: '',
  description: '',
  brand: '',
  department: '',
  category: '',
  listPrice: '',
  listPriceLabel: '',
  price: '',
  priceLabel: '',
  salePrice: '',
  stock: false,
  stockQuantity: '',
  picking: '',
  pricePerUnitLabel: '',
  pricePerUnitPicking: '',
  image: '',
  quantity: 0,
  error: false,
  errorMessage: '',
}

export const CartContextProvider = ({ children }: any) => {
  const [cart, setCart] = useState<ICartResponse | any>({
    items: [],
    totalizer: {},
  })
  const [layoutVars, setLayoutVars] = useState({})
  const [detailedItem, setDetailedItem] = useState<ICart | undefined>(
    blankProduct
  )
  const [alerts, setAlerts] = useState<IAlerts[]>([])
  const [finalizedOrder, setFinalizedOrder] = useState(false)
  const [cupomError, setCupomError] = useState(false)
  const [showTotalizer, setShowTotalizer] = useState(false)
  const [idOrder, setIdOrder] = useState('')
  const { setIsLoading } = useContext(LoadingContext)

  const { getCurrentHistory } = useHistoryStore()
  const { tokenState } = useTokenStore()
  const { headers } = useHeaderStore()
  const { cartItems, getRemoteCart, setRemoteCart } = useCartStore()

  const hasPaymentSet =
    cart && cart.payment ? !!cart.payment.chosenPayment : false
  const hasShippingSet =
    cart && cart.shipping ? !!cart.shipping.chosenShipping : false
  const cdChannel =
    tokenState && tokenState.cdChannel ? tokenState.cdChannel : null
  const brand = tokenState && tokenState.brand ? tokenState.brand : null

  const countCartItems = cart.items.filter(
    (item: ICart) => item.quantity > 0
  ).length

  const sortCart = (cart: ICart[]) => {
    if (cart.length) {
      const sortedCart = cart.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      )
      return sortedCart
    }
    return cart
  }

  const cartRequestItem = (item: ICart | null, method: CartMethods) => {
    if (item === null) {
      return JSON.stringify([])
    }
    switch (method) {
      case CartMethods.put:
        if (
          item.error &&
          item.errorMaxQuantity &&
          item.quantity <= item.errorMaxQuantity
        ) {
          delete item.error
          delete item.errorMaxQuantity
          delete item.errorMessage
        }

        setCart((prevState: any) => {
          if (prevState.items && prevState.items.length > 0) {
            let newCart = [
              ...prevState.items.filter(
                (cartItem: ICart) => cartItem.id !== item.id
              ),
              item,
            ]
            sortCart(newCart)
            return {
              ...prevState,
              items: newCart,
            }
          }
          return {
            ...prevState,
            items: [item],
          }
        })
        return JSON.stringify(cartItems)
      case CartMethods.delete:
        return JSON.stringify(cartItems)
      default:
        return JSON.stringify([item])
    }
  }

  const formatCartResponse = (response: ICartResponse) => {
    const newItems = response.items.map((item: any) => {
      return {
        ...item,
        name: item.dsSku,
        image: item.images ? item.images['200'] : null,
      }
    })
    return {
      ...response,
      items: newItems,
    }
  }

  const formatEmptyCartResponse = (response: ICartResponse) => {
    return {
      ...response,
      ...cart,
    }
  }

  const fetchRequest = async (
    requestData: ICartRequest,
    message?: any,
    clean?: boolean
  ) => {
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart`, requestData)
      .then((response) => response.json())
      .then((data) => {
        if (data.valid) {
          if (data.cart && data.cart.items.length === 0 && !clean) {
            setCart((prevState: any) => formatEmptyCartResponse(data.cart))
          }
          if (data.cart && data.cart.items.length > 0) {
            setCart((prevState: any) => formatCartResponse(data.cart))
          }
          if (message && !data.messages.length) {
            sendAlertMessage(message)
          }
        }
        setIsLoading(false)
        if (
          data.cart &&
          data.cart.items.length === 0 &&
          cart.items.length === 0
        )
          return
        if (data.messages && data.messages.length > 0) {
          data.messages.forEach((message: any) => {
            sendAlertMessage(message)
          })
        }
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }

  const updateCartData = async () => {
    setRemoteCart()
    // setIsLoading(true);
    // let requestData: ICartRequest = {
    //   method: CartMethods.put,
    //   headers: { ...headers },
    //   body: JSON.stringify(cartItems),
    // };
    // return await fetchRequest(requestData);
  }

  const cartRequest = async (
    method: CartMethods,
    item: ICart | null,
    message?: any,
    clean?: boolean
  ) => {
    let requestData
    if (!item && clean) {
      setIsLoading(true)
      requestData = {
        method: CartMethods.put,
        headers: { ...headers },
        body: cartRequestItem(item, method),
      }
      return fetchRequest(requestData, message, clean)
    }
    if (
      getCurrentHistory()!.route === 'Cart' &&
      method === CartMethods.delete
    ) {
      setIsLoading(true)
      requestData = {
        method: CartMethods.put,
        headers: { ...headers },
        body: cartRequestItem(item, method),
      }
      return fetchRequest(requestData, message)
    }

    if (item && item.quantity === 0 && getCurrentHistory()!.route !== 'Cart') {
      cartRequestItem(item, method)
      if (cart.items.length > 0) {
        cart.items.filter((cartItem: ICart) =>
          item.id === cartItem.id && message ? sendAlertMessage(message) : null
        )
      }
      return
    }

    if (getCurrentHistory()!.route !== 'Cart' && method !== CartMethods.get) {
      cartRequestItem(item, method)
      if (message) {
        sendAlertMessage(message)
      }
      return
    }

    if (getCurrentHistory()!.route === 'Cart' || method === CartMethods.get) {
      setIsLoading(true)
      setRemoteCart()
      // fetchRequest(requestData, message);
    }
  }

  const recoverCart = useCallback(() => {
    if (!headers.Authorization) return
    getRemoteCart()
  }, [headers])

  const addCoupon = async (coupon: string) => {
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart/coupon`, {
      method: CartMethods.post,
      headers: { ...headers },
      body: JSON.stringify({ coupon: coupon }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.valid && data.cart) {
          setCart(formatCartResponse(data.cart))
        }
        setIsLoading(false)
        data.messages.forEach((message: any) => {
          if (message.type === 'error') {
            setCupomError(true)
          }
          sendAlertMessage(message)
        })
        return
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }

  const removeCoupon = async () => {
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart/coupon`, {
      method: CartMethods.delete,
      headers: { ...headers },
      body: JSON.stringify({ coupon: cart.couponApplied }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.cart) {
          setCart(formatCartResponse(data.cart))
          setIsLoading(false)
        }
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }

  const selectPaymentMethod = async (paymentMethod: string) => {
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart/paymentMethod`, {
      method: CartMethods.post,
      headers: { ...headers },
      body: paymentMethod,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.cart) {
          setCart(formatCartResponse(data.cart))
        }
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }

  const selectShippingMethod = async (shippingMethod: string) => {
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart/shippingMethod`, {
      method: CartMethods.post,
      headers: { ...headers },
      body: shippingMethod,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.cart) {
          setCart(formatCartResponse(data.cart))
        }
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }

  const finalizeOrder = async () => {
    if (!hasPaymentSet) {
      return sendAlertMessage({
        type: 'error',
        message: 'Selecione um método de pagamento.',
      })
    }
    setIsLoading(true)
    return await fetch(`${tokenState.callBack}/ecommerce/cart/complete`, {
      method: CartMethods.post,
      headers: { ...headers },
      body: JSON.stringify({ idOrder: idOrder }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (!data.valid && data.messages.length > 0) {
          await data.messages.map(async (message: any) => {
            if (message.error === 'PLACE_ORDER_STOCK_INSUFFICIENT') {
              await sendStockMessages(message.message)
              return setIsLoading(false)
            } else {
              await sendAlertMessage(message)
              return setIsLoading(false)
            }
          })
        }
        if (data.valid) {
          setFinalizedOrder(true)
        }
        return setIsLoading(false)
      })
      .catch((e: any) => {
        setFinalizedOrder(false)
        setIsLoading(false)
      })
  }

  const setCartItemError = (errorItem: any) => {
    const newCart = cart.items.map((item: ICart) => {
      if (item.id === errorItem.id) {
        item.error = true
        item.errorMessage =
          CartErrorMessages.PLACE_ORDER_STOCK_INSUFFICIENT.replace(
            '{0}',
            errorItem.quantity
          )
        item.errorMaxQuantity = Number(errorItem.quantity)
      }
      return item
    })
    return newCart
  }

  const hideAlertMessage = (id: string) => {
    setAlerts((alerts: IAlerts[]) => {
      const setReaded = alerts.map((alert: IAlerts) => {
        if (alert.id === id) {
          alert.readed = true
        }
        return alert
      })
      return setReaded
    })
    setTimeout(() => {
      setAlerts((alerts: IAlerts[]) => {
        return alerts.filter((alert: IAlerts) => !alert.readed)
      })
    }, 700)
  }

  const sendStockMessages = async (messageArray: any) => {
    await sendAlertMessage({
      type: 'error',
      message: PlaceOrderMessages.PLACE_ORDER_STOCK_INSUFFICIENT,
    })
    messageArray.map(async (message: any) => {
      await setCartItemError(message)
      return message
    })
  }

  const sendAlertMessage = useCallback((message: any) => {
    const alertId = uniqueId()
    const alertBody = {
      id: alertId,
      type: message.type,
      message: message.message,
      readed: false,
      logged:
        message.type === 'warning' || message.type === 'error' ? true : false,
    }
    const customTimeout: any = {
      warning: 5000,
      error: 7000,
    }
    let timeout: number = 3000
    if (message.type === 'warning' || message.type === 'error') {
      timeout = customTimeout[message.type]
    }
    if (message.time) {
      timeout = message.time
    }
    setAlerts((alerts: any) => [...alerts, alertBody])
    setTimeout(() => {
      hideAlertMessage(alertId)
    }, timeout)
  }, [])

  useEffect(() => {
    if (cupomError) {
      setTimeout(() => {
        setCupomError(false)
      }, 5000)
    }
  }, [cupomError])

  useEffect(() => {
    if (tokenState) {
      setLayoutVars(tokenState.frontendFields)
      recoverCart()
    }
  }, [tokenState, recoverCart])

  return (
    <>
      <CartContext.Provider
        value={{
          cart,
          detailedItem,
          setDetailedItem,
          blankProduct,
          addCoupon,
          removeCoupon,
          selectPaymentMethod,
          selectShippingMethod,
          alerts,
          hideAlertMessage,
          finalizeOrder,
          layoutVars,
          finalizedOrder,
          hasPaymentSet,
          hasShippingSet,
          updateCartData,
          countCartItems,
          cupomError,
          sendAlertMessage,
          showTotalizer,
          setShowTotalizer,
          idOrder,
          setIdOrder,
          brand,
          cdChannel,
        }}
      >
        {children}
      </CartContext.Provider>
    </>
  )
}
