import { useCallback, useContext, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { ImagePlacer } from '@/Helpers/Image'
import { CartContext } from '@/Contexts/CartContext'
import ItemFlag from '@/Components/ItemFlag/ItemFlag'
import { CashbackIcon } from '@/Helpers/icons'
import HeaderLayout from '@/Layout/Header/Header'
import SuggestionDetails from '@/Layout/ProductDetails/Suggestions'
import TagsLayout from '@/Layout/Tags/Tags'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ShoppingCartIcon,
  XMarkIcon,
  PlusIcon,
  MinusIcon,
  TrashIcon,
  CubeIcon,
} from '@heroicons/react/24/outline'

import { useCartStore } from '@/Hooks/useCartStore'
import { useHistoryStore } from '@/Hooks/useHistory'
import { useToastStore } from '@/Hooks/useToastStore'

export default function ProductDetails() {
  const [details, setDetails] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [clearItem, setClearItem] = useState(false)
  const [openDescription, setOpenDescription] = useState(true)

  const {
    cartItems,
    addToCart,
    updateCart,
    removeFromCart,
    getOnCartItem,
    getTotalItems,
  } = useCartStore()
  const { addHistory } = useHistoryStore()

  const { detailedItem, setDetailedItem, blankProduct, layoutVars } =
    useContext(CartContext)
  const { addMessage } = useToastStore()

  const minimunQuantity = detailedItem.minimunQuantity
    ? detailedItem.minimunQuantity
    : 1
  const onCart = !!getOnCartItem(detailedItem.id)
    ? !!getOnCartItem(detailedItem.id)
    : false

  const resetValues = useCallback(() => {
    setDetailedItem(blankProduct)
    setQuantity(0)
  }, [])

  const attributesArray = detailedItem.attributes
    ? Object.keys(detailedItem.attributes).map((key) => {
        return { type: key, ...detailedItem.attributes[key] }
      })
    : []

  const suggestionObject =
    detailedItem.attributes && detailedItem.attributes.suggestion
      ? detailedItem.attributes.suggestion
      : {}

  useEffect(() => {
    const filtered = getOnCartItem(detailedItem.id)
    if (!!detailedItem.id) {
      setDetails(true)
    }
    if (!!filtered) {
      setQuantity(filtered.quantity)
    }

    return () => {}
  }, [cartItems, detailedItem, getOnCartItem])

  const close = (ev?: any) => {
    if (ev) {
      ev.preventDefault()
    }
    resetValues()
    setDetails(false)
    return
  }

  const addItem = (ev: any) => {
    ev.preventDefault()
    if (quantity <= 0) {
      detailedItem.quantity = 0
      removeFromCart(detailedItem)
      return close()
    }

    detailedItem.quantity = quantity
    onCart
      ? updateCart(detailedItem, quantity)
      : addToCart(detailedItem, quantity)
    return close()
  }

  const handleOnBlur = (ev: any) => {
    ev.preventDefault()
    if (clearItem) {
      setQuantity(0)
      ev.target.value = 0
    }
    if (ev.target.value > 0) {
      if (
        detailedItem.minimunQuantity &&
        quantity < detailedItem.minimunQuantity
      ) {
        addMessage(
          'warning',
          `A quantidade mínima para o item ${detailedItem.name} é de: ${detailedItem.minimunQuantity}.`
        )
        return setQuantity(parseFloat(detailedItem.minimunQuantity))
      }
    }
  }

  const handleOnFocus = (ev: any) => {
    ev.preventDefault()
    if (layoutVars.products && layoutVars.products.blockQuantityInput) return
    const end = ev.target.value.length
    ev.target.setSelectionRange(end, end)
    if (ev.target.value === '0') {
      ev.target.value = ''
    }
  }

  const handleOnChange = (ev: any, sourceInfo: any) => {
    setClearItem(false)
    if (sourceInfo.source === 'prop') return
    if (!ev.floatValue) {
      setClearItem(true)
      setQuantity(null!)
      return
    }

    if (ev.floatValue > parseFloat(detailedItem.stockQuantity)) {
      return setQuantity(parseFloat(detailedItem.stockQuantity))
    }
    if (ev.floatValue >= 0) {
      return setQuantity(ev.floatValue)
    }
    return
  }

  const addQuantity = () => {
    if (quantity < parseFloat(detailedItem.stockQuantity)) {
      setQuantity(quantity + minimunQuantity)
    }
  }
  const reduceQuantity = () => {
    return quantity - minimunQuantity > 0
      ? setQuantity(quantity - minimunQuantity)
      : setQuantity(0)
  }

  const picking = () => {
    if (detailedItem.picking) {
      return (
        <div className="justify-center items-center gap-1 inline-flex">
          <CubeIcon className="h-5 w-5 text-stone-500" />
          <div className="text-stone-500 text-xs font-normal leading-none">
            {detailedItem.picking}
          </div>
        </div>
      )
    }
    return <></>
  }

  const rgb = () => {
    if (detailedItem && detailedItem.rgb) {
      return (
        <div
          className="details-rgb rounded-start"
          style={{
            backgroundColor: `rgb(${detailedItem.rgb.replaceAll(' ', ',')})`,
          }}
        >
          <div className="color-title">Cor:</div>
          <div className="color-rgb"></div>
        </div>
      )
    }
  }

  const renderPrice = () => {
    if (layoutVars && layoutVars.products && layoutVars.products.price) {
      const pricePerUnit = () => {
        if (detailedItem.pricePerUnit) {
          return (
            <div className="per-unit text-stone-600 dark:text-stone-400 text-xs font-normal leading-none">
              (Aprox.:
              <NumericFormat
                value={parseFloat(detailedItem.pricePerUnit)}
                displayType={'text'}
                prefix="R$ "
                fixedDecimalScale={true}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
              />
              /
              {detailedItem.pricePerUnitPicking
                ? detailedItem.pricePerUnitPicking
                : 'un'}
              )
            </div>
          )
        }
        return
      }
      if (detailedItem.price && detailedItem.listPrice > detailedItem.price) {
        return (
          <div className="price">
            <p className="price-group">
              <span className="old-price text-stone-600 dark:text-stone-300 text-xs font-normal line-through leading-none">
                {detailedItem.listPriceLabel}
              </span>
              <span className="list-price text-brand-600 dark:text-brand-400 text-2xl font-bold leading-loose">
                {detailedItem.priceLabel}
              </span>
            </p>
            {pricePerUnit()}
          </div>
        )
      }
      return (
        <div className="price">
          <span className="list-price text-brand-600 dark:text-brand-400 text-2xl font-bold leading-loose">
            {detailedItem.listPriceLabel}
          </span>
          {pricePerUnit()}
        </div>
      )
    }
  }

  const attributes = () => {
    if (!detailedItem.attributes) return

    return attributesArray.map((attribute) => {
      return (
        <ItemFlag
          key={attribute.type}
          type={attribute.type}
          name={attribute.name}
          description={attribute.description}
          valueLabel={attribute.valueLabel}
          exp={attribute.exp}
          iat={attribute.iat}
        />
      )
    })
  }

  const displayPromotion = () => {
    if (
      !layoutVars.catalog ||
      !layoutVars.catalog.cashback ||
      attributesArray.length === 0
    )
      return
    return (
      <div className="row promotion-list">
        <div className="col-12">
          <h6 className="mb-0">
            {attributesArray.length >= 2
              ? 'Promoções ativas:'
              : 'Promoção ativa:'}
          </h6>
          <ul className="list-group-flush list-group text-start mt-0">
            {attributesArray.map((attribute: any) => {
              return (
                <li key={attribute.type} className="list-group-item">
                  <CashbackIcon size="14px" fill="#1f997e" />{' '}
                  {attribute.description} - válido até: {attribute.exp}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  const displaySuggestion = () => {
    if (
      !layoutVars.catalog ||
      !layoutVars.catalog.suggestion ||
      Object.keys(suggestionObject).length === 0
    )
      return
    return (
      <TagsLayout
        label={suggestionObject.suggestedReason}
        type="full"
        icon={suggestionObject.suggestedType}
      />
    )
  }
  return (
    <>
      <div className="relative z-30 overscroll-contain">
        <div
          className={`overscroll-contain backdrop fixed h-screen w-screen top-0 inset-x-0 backdrop-blur-sm bg-stone-100/30 dark:bg-stone-800/30 ${
            details ? 'visible' : 'invisible'
          }`}
          onClick={close}
        ></div>
        <div
          id="details-container"
          className={`
            transition-all duration-500 ease-in-out product-details-window z-40 h-full
            fixed top-0 inset-x-0 text-stone-700 dark:text-stone-300 bg-stone-100
            dark:bg-stone-900 overflow-hidden overscroll-contain
            lg:w-6/12 lg:mx-auto lg:rounded-lg lg:shadow-lg lg:h-5/6 lg:border lg:border-brand-600 lg:dark:border-stone-700
            lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2
            ${
              details
                ? 'visible opacity-100 translate-y-0'
                : 'translate-y-full opacity-0 invisible lg:hidden'
            }
          `}
        >
          <div className="invisible lg:visible lg:sticky top-0 inset-x-0 z-30">
            <XMarkIcon
              className="mt-4 ml-4 h-6 w-6 cursor-pointer"
              onClick={close}
            />
          </div>
          <div className="lg:hidden">
            <HeaderLayout columns={3}>
              <XMarkIcon className="h-6 w-6" onClick={close} />
              <h5 className="w-full flex-1 line-clamp-1 text-sm leading-tight font-medium">
                {detailedItem.name}
              </h5>
              <div
                className="flex-1 max-w-max h-6 w-6 shrink"
                onClick={() => {
                  close()
                  addHistory({ route: 'Cart' })
                }}
              >
                <div className="text-center relative">
                  <ShoppingCartIcon className="h-6 w-6 lg:mx-2" />
                  {getTotalItems() > 0 && (
                    <span className="absolute inline-flex items-center justify-center h-4 text-2xs px-1 text-white font-semibold leading-none bg-cta dark:bg-cta-light rounded-md -top-1 left-1/2">
                      {getTotalItems()}
                    </span>
                  )}
                </div>
              </div>
            </HeaderLayout>
          </div>

          <div className="product-details py-16 lg:pb-32 lg:pt-0 w-full h-full overscroll-contain">
            <div className="overflow-y-auto w-full h-full px-4">
              <div className="flex flex-col gap-4 justify-start items-start self-stretch h-full">
                <div className="flex-none product-image w-full h-48 overflow-hidden [&>img]:max-h-full flex items-center justify-center">
                  <ImagePlacer
                    image={detailedItem.image}
                    name={detailedItem.name}
                  />
                  {rgb()}
                </div>
                <div className="flex flex-col gap-2">
                  {displaySuggestion()}
                  <div className="product-name text-stone-700 dark:text-stone-200 text-lg font-medium leading-7">
                    <h5 className="w-full line-clamp-2">{detailedItem.name}</h5>
                  </div>
                  <div className="text-stone-600 dark:text-stone-400 text-sm font-normal leading-tight inline-flex justify-start gap-4">
                    {layoutVars.products &&
                      layoutVars.products.ean &&
                      detailedItem.cdEan && (
                        <div className="justify-start items-center gap-1 inline-flex">
                          <div className="text-sm font-normal leading-none">
                            EAN: {detailedItem.cdEan}
                          </div>
                        </div>
                      )}
                    {layoutVars.products &&
                      layoutVars.products.sku &&
                      detailedItem.cdSku && (
                        <div className="justify-start items-center gap-1 inline-flex">
                          <div className="text-sm font-normal leading-none">
                            SKU: {detailedItem.cdSku}
                          </div>
                        </div>
                      )}
                    {detailedItem.picking && (
                      <div className="self-stretch grow shrink justify-start items-center gap-1 inline-flex">
                        <CubeIcon className="h-5 w-5" />
                        <div className="text-sm font-normal leading-none">
                          {detailedItem.picking}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>{renderPrice()}</div>
                {layoutVars.catalog &&
                  layoutVars.catalog.suggestion &&
                  Object.keys(suggestionObject).length > 0 && (
                    <SuggestionDetails
                      suggestionObject={suggestionObject}
                      quantity={quantity}
                      setQuantity={setQuantity}
                    />
                  )}
                <div className="w-full border-t border-stone-300 dark:border-stone-700 py-4">
                  <div
                    className="inline-flex justify-between items-center w-full cursor-pointer"
                    onClick={() => setOpenDescription(!openDescription)}
                  >
                    <div className="text-stone-700 dark:text-stone-200 text-base font-medium leading-normal">
                      Detalhes
                    </div>
                    <div>
                      {openDescription ? (
                        <ChevronUpIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" />
                      ) : (
                        <ChevronDownIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" />
                      )}
                    </div>
                  </div>
                  <div
                    className={`mt-4 ${openDescription ? 'block' : 'hidden'}`}
                  >
                    <div className="text-stone-600 dark:text-stone-400 text-sm font-normal leading-tight">
                      {detailedItem.description}
                    </div>
                  </div>
                </div>
                <div>{displayPromotion()}</div>
                <div className="description-content">
                  {detailedItem.attributes &&
                    detailedItem.attributes.grossWeight &&
                    layoutVars &&
                    layoutVars.cart.itemWeight && (
                      <div className="text-stone-600 text-xs font-normal leading-none">
                        Peso bruto:{' '}
                        {detailedItem.attributes.grossWeight.toFixed(2)}kg
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <footer className="fixed w-screen bottom-0 text-center h-16 py-2 px-4 bg-stone-50 dark:bg-stone-800 rounded-t-lg z-30 lg:container">
            <div className="flex items-center justify-between h-full gap-2">
              <div className="quick-add-to-cart rounded-lg bg-stone-50 dark:bg-stone-900 text-cta h-11 overflow-hidden ring-1 ring-inset ring-cta p-2 w-full dark:ring-cta-light dark:text-cta-light">
                <div className="inline-flex items-center justify-center content-center h-full lg:w-full">
                  <button
                    disabled={quantity === 0 ? true : false}
                    className="btn h-5 w-5 disabled:text-stone-300 dark:disabled:text-stone-700"
                    onClick={reduceQuantity}
                  >
                    {quantity <= 1 || quantity === minimunQuantity ? (
                      <TrashIcon className="h-5 w-5" />
                    ) : (
                      <MinusIcon className="h-5 w-5" />
                    )}
                  </button>
                  <NumericFormat
                    value={quantity}
                    displayType={'input'}
                    allowNegative={false}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={
                      layoutVars.products && layoutVars.products.decimalPlaces
                        ? layoutVars.products.decimalPlaces
                        : 2
                    }
                    readOnly={
                      layoutVars.products &&
                      layoutVars.products.blockQuantityInput
                        ? layoutVars.products.blockQuantityInput
                        : false
                    }
                    suffix={
                      layoutVars.products && layoutVars.products.pickingOnQty
                        ? ` ${detailedItem.picking}`
                        : ''
                    }
                    inputMode="decimal"
                    className="text-sm text-center outline-none font-semibold bg-stone-50 dark:bg-stone-900 grow h-5 w-2/3 align-middle indent-0"
                    onValueChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onFocus={handleOnFocus}
                  />
                  <button className="btn h-5 w-5" onClick={addQuantity}>
                    <PlusIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
              <div className="details-add w-full h-11">
                <button
                  className="rounded-lg bg-cta dark:bg-cta-light w-full h-full text-lg font-semibold text-white"
                  onClick={addItem}
                >
                  {onCart && detailedItem.quantity > 0
                    ? 'Alterar'
                    : 'Adicionar'}
                </button>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}
