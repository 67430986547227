import Footer from '@/Components/Footer/Footer';
import Products from '@/Components/Products/Products';
import ProductsSuggestions from '../Products/ProductsSuggestions';
import ProductDetails from '@/Components/ProductDetails/ProductDetails';
import HeaderAlt from '@/Components/Header/HeaderAlt';
import Header from '@/Components/Header/Header';

import { useHistoryStore } from '@/Hooks/useHistory';
import { useCategoryStore } from '@/Hooks/useCategoryStore';

export default function CategoryView() {
  const { getCurrentParams } = useHistoryStore();
  const { activeCategory } = useCategoryStore();
  const suggestionParams = getCurrentParams()

  const categoryTitle = (suggestionParams && suggestionParams.display === 'SUGGESTIONS') ? suggestionParams.category : activeCategory.name;

  return(
    <>
      <div className="invisible lg:visible lg:sticky top-0 inset-x-0 z-30">
        <Header />
      </div>
      <div className="lg:hidden">
        <HeaderAlt title={categoryTitle} />
      </div>
      <div className="content w-full h-full pt-16 lg:pt-8 lg:mb-16 lg:container lg:mx-auto">
        {
          (suggestionParams && suggestionParams.display === 'SUGGESTIONS')
          ? <ProductsSuggestions />
          : <Products />
        }
      </div>
      <Footer />
      <ProductDetails />
    </>
  );
}