import { useState } from 'react';
import SearchBar from '@/Layout/SearchBar/SearchBar';
import HeaderLayout from '@/Layout/Header/Header'
import { MagnifyingGlassIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

import { useStore } from 'zustand';
import { useHistoryStore } from '@/Hooks/useHistory';

type HeaderAltProps = {
  title: string;
}

export default function HeaderAlt(props: HeaderAltProps) {
  const { title } = props;
  const [isSearch, setIsSearch] = useState(false);
  const { goBack } = useStore(useHistoryStore);

  const searchHeader = () => {
    return (
      <HeaderLayout columns={2}>
        <ChevronLeftIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" onClick={() => setIsSearch(false)} />
        <SearchBar />
      </HeaderLayout>
    )
  }

  const defaultHeader = () => {
    return (
      <HeaderLayout columns={3}>
        <ChevronLeftIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" onClick={goBack} />
        <h5 className="w-full flex-1 line-clamp-1 text-base leading-tight font-semibold text-stone-700 dark:text-stone-200">
          { title }
        </h5>
        <MagnifyingGlassIcon className={`h-6 w-6 text-stone-700 dark:text-stone-200`} onClick={() => setIsSearch(true)} />
      </HeaderLayout>
    )
  }

  return (
    <>
      {
        isSearch
        ? searchHeader()
        : defaultHeader()
      }
    </>
  );
}
