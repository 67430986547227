import {create} from 'zustand';

type HeaderState = {
  headers: Record<string, string>;
  setHeader: (name: string, value: string) => void;
  removeHeader: (name: string) => void;
};

export const useHeaderStore = create<HeaderState>((set) => ({
  headers: {},
  setHeader: (name, value) =>
    set((state) => ({
      headers: {
        ...state.headers,
        [name]: value,
      },
    })),
  removeHeader: (name) =>
    set((state) => {
      const { [name]: _, ...rest } = state.headers;
      return { headers: rest };
    }),
}));
