import React from 'react'
import Categories from '@/Components/Categories/Categories'
import Footer from '@/Components/Footer/Footer'
import ProductDetails from '@/Components/ProductDetails/ProductDetails'
import Header from '@/Components/Header/Header'
import { CartContext } from '@/Contexts/CartContext'

import Suggestions from '@/Components/Suggestions/Sugestions'
import RandomCategories from '@/Components/Categories/RandomCategories'
import SingleCategory from '@/Components/Categories/SingleCategory'
import { useCategoryStore } from '@/Hooks/useCategoryStore'

export default function Home(props: any) {
  const { layoutVars } = React.useContext(CartContext)
  const { categories } = useCategoryStore()

  return (
    <>
      <Header />
      <div className="content w-full h-full pt-16 lg:py-8 lg:container lg:mx-auto">
        <div className="overflow-y-auto h-full lg:flex lg:h-auto lg:overflow-visible">
          {((layoutVars.catalog && layoutVars.catalog.suggestion) ||
            categories.length > 1) && (
            <div className="lg:block lg:w-3/12 xl:w-2/12 lg:relative lg:px-4">
              <div className="sticky top-24 h-auto">
                <Categories />
              </div>
            </div>
          )}
          <div
            className={`content ${
              (layoutVars.catalog && layoutVars.catalog.suggestion) ||
              categories.length > 1
                ? 'lg:w-9/12 xl:w-10/12'
                : 'w-full'
            }`}
          >
            {layoutVars.catalog && layoutVars.catalog.suggestion && (
              <Suggestions randomCategories={props.randomCategories} />
            )}
            {categories.length > 1 ? (
              <RandomCategories randomCategories={props.randomCategories} />
            ) : (
              <SingleCategory />
            )}
          </div>
        </div>
      </div>
      <Footer />
      <ProductDetails />
    </>
  )
}
