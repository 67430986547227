import React from 'react';

export const ProductSkeletonLoading: React.FC = () => {
  return (
    <div className="card bg-stone-50 shadow-md dark:bg-stone-800 shadow-stone-950/10 rounded-xl p-2 flex flex-col animate-pulse flex-none">
      <div className="product-image w-full h-23 flex items-center justify-center">
      <svg className="w-10 h-10 text-stone-200 dark:text-stone-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
      </svg>
      </div>
      <div className="product-title text-xs grow">
        <p className="h-3 bg-stone-200 rounded-full dark:bg-stone-700 w-11/12 mb-1" />
        <p className="h-3 bg-stone-200 rounded-full dark:bg-stone-700 w-10/12 mb-2" />
      </div>
      <div className="product-info text-xs text-stone-500 dark:text-stone-400">
        <p className="mt-1 h-2 bg-stone-200 rounded-full dark:bg-stone-700 w-6/12 mb-2.5" />
        <p className="mt-1 h-2 bg-stone-200 rounded-full dark:bg-stone-700 w-3/12 mb-2.5" />
      </div>
      <div className="product-price font-semibold text-sm text-brand-600 dark:text-brand-400">
        <div className="price">
          <span className="list-price mt-1">
            <p className="mt-1 h-3 bg-stone-200 rounded-full dark:bg-stone-700 w-2/12 mb-2.5" />
          </span>
        </div>
      </div>
      <div className="product-button mt-1">
        <p className="rounded-lg w-full h-9 bg-stone-200 dark:bg-stone-700" />
      </div>
    </div>
  )
}