import React, { useEffect, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ProductSkeletonLoading } from '@/Helpers/skeletonLoading'
import QuickProductAdd from '@/Components/QuickProductAdd/QuickProductAdd'

import { useCategoryStore } from '@/Hooks/useCategoryStore'
import { useHistoryStore } from '@/Hooks/useHistory'
import { useFetchStore } from '@/Hooks/useFetchStore'
import { getProducts } from '@/Api/products'

const PAGE_SIZE = 20

export default function RandomCategories() {
  const { categories } = useCategoryStore()
  const { getFetchDetails } = useFetchStore()
  const { addHistory } = useHistoryStore()

  const { headers, callback } = getFetchDetails()

  const loaderRef = useRef<HTMLDivElement>(null)
  const category = categories[0]

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ['products', category.link],
    queryFn: ({ pageParam = 0 }) =>
      getProducts(category.link, pageParam, PAGE_SIZE, headers, callback),
    getNextPageParam: (lastPage) =>
      lastPage.result.length === 20 ? lastPage.index + 1 : undefined,
    retry: (failureCount, error) => {
      const errorMessage = error as string
      if (errorMessage.toString().includes('401')) {
        addHistory({ route: 'Blocked' })
        return false
      }
      if (failureCount > 2) return false
      return true
    },
  })

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      if (loaderRef.current) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (
              entries[0].isIntersecting &&
              hasNextPage &&
              !isFetchingNextPage
            ) {
              fetchNextPage()
            }
          },
          {
            threshold: 1.0,
          }
        )
        observer.observe(loaderRef.current)
      }
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const productSkeleton = () => {
    return (
      <>
        {Array.from({ length: PAGE_SIZE }).map((_, index) => (
          <ProductSkeletonLoading key={index} />
        ))}
      </>
    )
  }
  return (
    <>
      <h5 className="font-medium px-4 text-xl leading-7 mt-6 text-brand-500 dark:text-brand-400 lg:mt-4">
        {category.name}
      </h5>
      <div
        id="product-container"
        className="content w-full h-full lg:mb-16 lg:container lg:mx-auto px-4"
      >
        <div className="mt-3 lg:mb-0 overflow-y-auto h-full lg:px-0 lg:flex lg:h-auto lg:overflow-visible">
          <div
            id="product-list"
            className="w-full grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-x-2 gap-y-4 auto-rows-min lg:gap-x-4 lg:gap-y-8 pb-20"
          >
            {isLoading ? (
              productSkeleton()
            ) : status === 'error' ? (
              <p>Error: </p>
            ) : (
              <>
                {data.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.result.map((item: any) => (
                      <QuickProductAdd item={item} key={item.id} />
                    ))}
                  </React.Fragment>
                ))}
                {isFetchingNextPage ? (
                  productSkeleton()
                ) : hasNextPage ? (
                  <div
                    ref={loaderRef}
                    className="products-scroll h-8 z-50"
                  ></div>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
