import React from 'react'
import { HomeIcon, UserIcon, ListBulletIcon } from '@heroicons/react/24/outline'
import {
  HomeIcon as HomeIconSolid,
  UserIcon as UserIconSolid,
  ListBulletIcon as ListBulletIconSolid,
} from '@heroicons/react/24/solid'

import { CartContext } from '@/Contexts/CartContext'
import { useHistoryStore } from '@/Hooks/useHistory'
import { useCategoryStore } from '@/Hooks/useCategoryStore'

import CartButton from '@/Layout/Footer/CartButton'
import AccountButton from '@/Layout/Footer/AccountButton'

export default function Footer() {
  const { layoutVars } = React.useContext(CartContext)
  const { addHistory, goHome, checkCurrentHistory } = useHistoryStore()
  const { categories } = useCategoryStore()

  const handleClick = (ev: any, area: string) => {
    ev.preventDefault()
    addHistory({ route: area })
  }
  return (
    <footer
      className="fixed inset-x-2 bottom-2 w-11/12 h-14 m-auto py-0 bg-stone-50 text-stone-600 dark:bg-stone-700
      dark:text-stone-300 text-center flex flex-row items-center justify-arround z-20 shadow-center shadow-stone-950/30
      rounded-2xl lg:hidden [&>.active]:text-brand-500 [&>.active]:dark:text-brand-400"
    >
      <div
        className={`flex-1 ${checkCurrentHistory('Home') ? 'active' : ''}`}
        onClick={goHome}
      >
        <div className={`flex flex-col items-center text-xs text-center`}>
          {checkCurrentHistory('Home') ? (
            <HomeIconSolid className="h-5 w-5" />
          ) : (
            <HomeIcon className="h-5 w-5" />
          )}
          Catálogo
        </div>
      </div>
      {((layoutVars.catalog && layoutVars.catalog.suggestion) ||
        categories.length > 1) && (
        <div
          className={`flex-1 ${
            checkCurrentHistory('CategoryList') ||
            checkCurrentHistory('CategoryView')
              ? 'active'
              : ''
          }`}
          onClick={(ev) => handleClick(ev, 'CategoryList')}
        >
          <div className={`flex flex-col items-center text-xs text-center`}>
            {checkCurrentHistory('CategoryList') ? (
              <ListBulletIconSolid className="h-5 w-5" />
            ) : (
              <ListBulletIcon className="h-5 w-5" />
            )}
            Categorias
          </div>
        </div>
      )}

      <div
        className={`flex-1 ${checkCurrentHistory('Cart') ? 'active' : ''}`}
        onClick={(ev) => handleClick(ev, 'Cart')}
      >
        <CartButton size="h-5 w-5" text="Carrinho" />
      </div>

      <div
        className={`flex-1 ${checkCurrentHistory('Account') ? 'active' : ''}`}
        onClick={(ev) => handleClick(ev, 'Account')}
      >
        <AccountButton size="h-5 w-5" text="Conta" />
      </div>
    </footer>
  )
}
