import React from 'react';
import { UserIcon} from '@heroicons/react/24/outline';
import { UserIcon as UserIconSolid} from '@heroicons/react/24/solid';
import { useHistoryStore } from '@/Hooks/useHistory';

interface IHeaderProps {
  size: string;
  text?: string;
}

const AccountButton: React.FC<IHeaderProps> = ({ size, text }) => {
  const { addHistory,  checkCurrentHistory } = useHistoryStore();

  return (
    <div
      className="flex flex-col items-center text-xs text-center"
      onClick={() => addHistory({route: 'Account'})}
    >
      {
        checkCurrentHistory('Account')
        ? <UserIconSolid className={`${size}`} />
        : <UserIcon className={`${size}`} />
      }
      { text &&
        text
      }
    </div>
  );
};

export default AccountButton;