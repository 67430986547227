import { useCallback, useState } from 'react';
import {CameraIcon} from '@heroicons/react/24/outline';

interface CategoriesImagesProps {
  imageUrl: string;
  imageAlt: string;
  cdChannel: string;
}

export default function CategoriesImages({ imageUrl, imageAlt, cdChannel }: CategoriesImagesProps) {
  const [imageError, setImageError] = useState(false);

  const defaultImage = useCallback(() => {
    return (
      <div className="flex justify-center items-center">
        <CameraIcon className="h-6 w-6 text-brand-500 dark:text-brand-400" />
      </div>
    );
  }, []);

  const handleImageError = useCallback((event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const image = event.target as HTMLImageElement;
    image.onerror = null;
    setImageError(true);
  }, []);

  const memoizedImageSource = useCallback(() => {
    return `static/categories/${cdChannel}/${imageUrl}`;
  }, [imageUrl, cdChannel]);

  return (
    <>
      {imageError ? (
        defaultImage()
      ) : (
        <img src={memoizedImageSource()} alt={imageAlt} className='max-h-full' onError={handleImageError} />
      )}
    </>
  );
}
