import ProductsHorizontal from '@/Components/Products/ProductsHorizontal';

import { useCategoryStore } from "@/Hooks/useCategoryStore";

export default function RandomCategories(props: any) {
  const { randomCategories } = props;
  const  { setActiveCategory } = useCategoryStore();

  return(
    <>
      <h5 className='font-medium px-4 text-xl leading-7 mt-6 text-brand-500 dark:text-brand-400 lg:mt-4'>Categorias em destaque</h5>
      <div className="mt-3 mb-36 lg:mb-0">
        {randomCategories.map((category: any) => {
          return (
            <div key={category.name} className='mt-6 auto-rows-min'>
              <div className="px-4 flex justify-between align-middle items-center lg:px-12">
                <span className='text-stone-700 dark:text-stone-300 text-sm font-medium leading-tight'>{category.name}</span>
                <span className='text-brand-600 dark:text-brand-400 text-xs font-semibold leading-none cursor-pointer' onClick={() => setActiveCategory(category)}>Ver mais</span>
              </div>
              <ProductsHorizontal category={category} />
            </div>
          )
        })}
      </div>
    </>
  );
}
