import { create } from 'zustand';

type TokenState = {
  tokenState: any | null;
  setFields: (fields: string | null) => void;
  setToken: (token: string | null) => void;
  setCustomer: (customer: string | null) => void;
  clearToken: () => void;
};

export const useTokenStore = create<TokenState>((set, get) => ({
  tokenState: {},
  setFields: (fields) => {
    set((state) => ({ tokenState: { ...state.tokenState, frontendFields: JSON.parse(fields!) } }));
  },
  setToken: (token) => {
    if (token) {
      set(() => ({ tokenState: token }));
    } else {
      set(() => ({ tokenState: null }));
    }
  },
  setCustomer: (customer) => {
    set((state) => ({ tokenState: { ...state.tokenState, customerCallback: customer } }));
  },
  clearToken: () => set(() => ({ tokenState: null })),
}));
