import { create } from 'zustand';
import { ICart } from '@/Interfaces/Cart';

import { useHistoryStore } from '@/Hooks/useHistory';
import { useLoadingStore } from '@/Hooks/useLoadingStore';
import { useFetchStore } from '@/Hooks/useFetchStore';

type CartStore = {
  cartItems: ICart[];
  cartResponse: any | {};
  idOrder: string;
  hasShipping: boolean;
  hasPayment: boolean;
  isFinalized: boolean;
  addToCart: (item: ICart, quantity: number) => void;
  updateCart: (item: ICart, quantity: number) => void;
  removeFromCart: (item: ICart) => void;
  clearCart: () => void;
  getTotalItems: () => number;
  getOnCartItem: (id: string) => ICart | undefined;
  fetchCart: (method: string, body?: ICart[]) => Promise<any> | Promise<ICart[]>;
  setRemoteResponse: (response: any) => void;
  getRemoteCart: () => void;
  setRemoteCart: () => void;
  setCoupon: (coupon: string) => void;
  removeCoupon: () => void;
  setShippingMethod: (shippingMethod: string) => void;
  setPaymentMethod: (paymentMethod: string) => void;
  finalizeOrder: () => void;
  setIdOrder: (idOrder: string) => void;
  getErrorItems: () => ICart[];
  clearErrorItems: () => void;
};

const addHistory = useHistoryStore.getState().addHistory;
const setIsLoading = useLoadingStore.getState().setIsLoading;
const fetchUrl = useFetchStore.getState().fetchUrl;

export const useCartStore = create<CartStore>((set, get) => ({
  cartItems: [],
  cartResponse: {},
  idOrder: '',
  hasShipping: false,
  hasPayment: false,
  isFinalized: false,
  addToCart: (item, quantity) =>
    set((state) => {
      const existingItem = state.cartItems.find((i) => i.id === item.id);
      if (existingItem) {
        return {
          cartItems: state.cartItems.map((i) =>
            i.id === item.id ? { ...i, quantity: i.quantity + quantity } : i
          ),
        };
      } else {
        return {
          cartItems: [...state.cartItems, {...item, quantity: quantity}],
        };
      }
    }),
  updateCart: (item, quantity) =>
    set((state) => {
      return {
        cartItems: state.cartItems.map((i) =>
          i.id === item.id ? { ...i, quantity: quantity } : i
        ),
      };
    }),
  removeFromCart: (item: ICart) =>
    set((state) => ({
      cartItems: state.cartItems.filter((i) => i.id !== item.id),
    })),
  clearCart: () =>
    set(() => ({
      cartItems: [],
    })),
  getTotalItems: () => {
    const cart = get().cartItems;
    return cart.length;
  },
  getOnCartItem: (id: string) => {
    const cart = get().cartItems;
    return cart.find((item) => item.id === id);
  },
  fetchCart: async (method, body) => {
    setIsLoading(true);
    const response = await fetchUrl('ecommerce/cart', method, body);
    setIsLoading(false);
    return response;
  },
  setRemoteResponse: (response) => {
    if (response.status === 200) {
      if ((response.cart.shipping && response.cart.shipping.chosenShipping) || !response.cart.shippingMethods) {
        set(() => ({
          hasShipping: true
        }));
      }
      if ((response.cart.payment && response.cart.payment.chosenPayment) || !response.cart.paymentMethods) {
        set(() => ({
          hasPayment: true
        }));
      }
      set(() => ({
        cartResponse: response
      }));
    } else {
      addHistory({ route: 'Blocked' });
    }
  },
  getRemoteCart: async () => {
    const response = await get().fetchCart('GET');
    if (response.status === 200) {
      set(() => ({
        cartItems: response.cart.items,
        cartResponse: response
      }));
    } else {
      addHistory({ route: 'Blocked' });
    }
  },
  setRemoteCart: async () => {
    const response = await get().fetchCart('PUT', get().cartItems);
    get().setRemoteResponse(response);
  },
  setCoupon: async (coupon) => {
    setIsLoading(true);
    const response = await fetchUrl('ecommerce/cart/coupon', 'POST', coupon);
    setIsLoading(false);
    get().setRemoteResponse(response);
  },
  removeCoupon: async () => {
    setIsLoading(true);
    const response = await fetchUrl('ecommerce/cart/coupon', 'DELETE');
    setIsLoading(false);
    get().setRemoteResponse(response);
  },
  setShippingMethod: async (shippingMethod) => {
    setIsLoading(true);
    const response = await fetchUrl('ecommerce/cart/shippingMethod', 'POST', shippingMethod);
    setIsLoading(false);
    get().setRemoteResponse(response);
  },
  setPaymentMethod: async (paymentMethod) => {
    setIsLoading(true);
    const response = await fetchUrl('ecommerce/cart/paymentMethod', 'POST', paymentMethod);
    setIsLoading(false);
    get().setRemoteResponse(response);
  },
  finalizeOrder: async () => {
    setIsLoading(true);
    const idOrder = get().idOrder;
    const response = await fetchUrl('ecommerce/cart/complete', 'POST', {idOrder: idOrder});
    if (response.status === 200 && response.valid) {
      set(() => ({
        isFinalized: true
      }));
    }
    setIsLoading(false);
  },
  setIdOrder: (idOrder) =>
    set(() => ({
      idOrder: idOrder
    })),
    getErrorItems: () => {
      const cart = get().cartResponse.cart.items
      return cart.filter((item: any) => item.error);
    },
    clearErrorItems: () =>
    set(() => ({
      cartItems: get().cartResponse.cart.items.filter((item: any) => !item.error)
    })),
}));
