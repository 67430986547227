import React from 'react'
import pedmais from '../Assets/images/pedmais-simple.png'
import rioquality from '../Assets/images/rioquality.png'
import cbb from '../Assets/images/cbb.png'
import catupiry from '../Assets/images/catupiry.png'
import primeDark from '../Assets/images/prime_cater_dark.png'
import primeLight from '../Assets/images/prime_cater.png'

import { useTokenStore } from '../Hooks/useTokenStore'

const LogoHandler: React.FC = () => {
  const { tokenState } = useTokenStore()
  switch (tokenState.cdChannel) {
    case 'RIO_QUALITY':
      return (
        <img
          src={rioquality}
          alt="Rio Quality"
          className="h-auto lg:max-h-12"
        />
      )
    case 'sg':
      if (tokenState.brand === 'WISO') {
        return (
          <img
            src={`static/brands/${tokenState.cdChannel}/WISO.png`}
            alt="Saint-Gobain"
            className="h-auto dark:bg-stone-100 p-0.5 rounded-lg lg:max-h-12 lg:p-0"
          />
        )
      }
      return (
        <img
          src={`static/brands/${tokenState.cdChannel}/${tokenState.brand}.png`}
          alt="Saint-Gobain"
          className="h-auto lg:max-h-12"
        />
      )
    case 'CBB':
      return (
        <img src={cbb} alt="CBB Distribuidora" className="h-auto lg:max-h-10" />
      )
    case 'catupiry':
      return (
        <img
          src={catupiry}
          alt="Catupiry"
          className="h-auto max-h-10 lg:max-h-12"
        />
      )
    case 'PRIME':
      return (
        <>
          <img
            src={primeDark}
            alt="Prime Cater"
            className="h-auto max-h-10 lg:max-h-12 hidden dark:block"
          />
          <img
            src={primeLight}
            alt="Prime Cater"
            className="h-auto max-h-10 lg:max-h-12 dark:hidden"
          />
        </>
      )
    case 'pedmais':
    default:
      return <img src={pedmais} alt="ped+" className="h-auto lg:max-h-12" />
  }
}

export default LogoHandler
