import { useState } from 'react'
import { IProduct } from '@/Interfaces/Products'
import QuickProductAdd from '@/Components/QuickProductAdd/QuickProductAdd'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { useHistoryStore } from '@/Hooks/useHistory'
import { useEffectOnce } from 'react-use'
import SuggestionIconByType from '@/Helpers/SuggestionIcon'

type ProductsProps = {
  type: string
  category: string
  productsArray: IProduct[]
}

export default function SuggestionProductsHorizontal(props: ProductsProps) {
  const { type, category, productsArray } = props
  const [hasNext, setHasNext] = useState(false)
  const { addHistory } = useHistoryStore()

  const scrollAmount = 200

  const scrollDiv = (direction: 'left' | 'right', amount: number) => {
    const productsDiv = document.getElementById(
      `suggest-${category}`
    ) as HTMLDivElement
    if (direction === 'left') {
      productsDiv.scrollBy({ left: -amount, behavior: 'smooth' })
    } else {
      productsDiv.scrollBy({ left: amount, behavior: 'smooth' })
    }
  }

  const isOverflowing = (div: HTMLDivElement, axis: 'x' | 'y') => {
    if (axis === 'x') {
      return !!(div.scrollWidth > div.clientWidth)
    } else {
      return !!(div.scrollHeight > div.clientHeight)
    }
  }

  useEffectOnce(() => {
    const productsDiv = document.getElementById(
      `suggest-${category}`
    ) as HTMLDivElement
    if (isOverflowing(productsDiv, 'x')) {
      setHasNext(true)
    }
  })

  return (
    <>
      <div className="px-4 pt-3 flex justify-between align-middle items-center lg:px-12">
        <div className="text-stone-700 dark:text-stone-300 text-sm font-medium leading-tight inline-flex items-center">
          <SuggestionIconByType type={type} />
          <span className="mx-2">{category}</span>
        </div>
        <div
          className="text-xs text-brand-600 dark:text-brand-400 font-semibold cursor-pointer"
          onClick={() =>
            addHistory({
              route: 'CategoryView',
              params: {
                display: 'SUGGESTIONS',
                type: type,
                category: category,
              },
            })
          }
        >
          Ver mais
        </div>
      </div>
      <div className="w-full h-full items-center lg:flex lg:justify-between lg:px-4">
        <div
          className={`hidden lg:flex h-full justify-center items-center cursor-pointer ${
            hasNext ? 'visible' : 'invisible'
          }`}
          onClick={() => scrollDiv('left', scrollAmount)}
        >
          <ChevronLeftIcon className="h-8 w-8 text-stone-700 dark:text-stone-200" />
        </div>
        <div
          id={`suggest-${category}`}
          className="
            flex w-full auto-rows-min overflow-x-auto hide-scroll-bar
            whitespace-no-wrap gap-2 py-3 px-4 snap-x [&>div.card]:flex-none
            [&>div.card]:w-7/12 [&>div.card]:snap-center [&>div.card]:lg:w-4/12
            [&>div.card]:xl:w-3/12 [&>div.card]:2xl:w-1/5
            lg:gap-6 lg:px-0
          "
        >
          {productsArray.map((item: IProduct) => (
            <QuickProductAdd item={item} key={item.id} />
          ))}
        </div>
        <div
          className={`hidden lg:flex h-full justify-center items-center cursor-pointer ${
            hasNext ? 'visible' : 'invisible'
          }`}
          onClick={() => scrollDiv('right', scrollAmount)}
        >
          <ChevronRightIcon className="h-8 w-8 text-stone-700 dark:text-stone-200" />
        </div>
      </div>
    </>
  )
}
