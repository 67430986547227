import { useState } from 'react'
import LogoHandler from '@/Helpers/LogoHandler'
import CartButton from '@/Components/Footer/Footer'
import Category from '@/Components/Categories/Category'
import HeaderLayout from '@/Layout/Header/Header'
import SearchBar from '@/Layout/SearchBar/SearchBar'
import {
  MagnifyingGlassIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useCategoryStore } from '@/Hooks/useCategoryStore'

export default function CategoryList() {
  const [isSearch, setIsSearch] = useState(false)

  const { goHome } = useHistoryStore()
  const { categories } = useCategoryStore()

  const handleHeader = () => {
    switch (isSearch) {
      case true:
        return searchHeader()
      case false:
      default:
        return defaultHeader()
    }
  }

  const searchHeader = () => {
    return (
      <HeaderLayout columns={2}>
        <ChevronLeftIcon
          className="h-6 w-6 text-stone-700 dark:text-stone-200"
          onClick={() => setIsSearch(false)}
        />
        <SearchBar />
      </HeaderLayout>
    )
  }

  const defaultHeader = () => {
    return (
      <HeaderLayout columns={3} display="basis">
        <div
          className="flex items-start justify-start w-full max-h-11"
          onClick={goHome}
        >
          <LogoHandler />
        </div>
        <h5 className="line-clamp-1 text-base leading-tight font-semibold text-stone-700 dark:text-stone-200">
          Categorias
        </h5>
        <div className="w-full flex items-end justify-end">
          <MagnifyingGlassIcon
            className={`h-6 w-6 text-stone-700 dark:text-stone-200`}
            onClick={() => setIsSearch(true)}
          />
        </div>
      </HeaderLayout>
    )
  }

  return (
    <>
      {handleHeader()}
      <div className="content py-24 mx-4 h-screen overflow-y-auto">
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-4 mb-28">
          {categories.map((category: any) => (
            <div
              className="flex items-start text-xs font-medium justify-center"
              key={category.link}
            >
              <Category category={category} />
            </div>
          ))}
        </div>
      </div>
      <CartButton />
    </>
  )
}
