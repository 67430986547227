import React, { useEffect, useState, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ProductSkeletonLoading } from '@/Helpers/skeletonLoading'

import ProductDetails from '@/Components/ProductDetails/ProductDetails'
import CartButton from '@/Components/Footer/Footer'
import QuickProductAdd from '@/Components/QuickProductAdd/QuickProductAdd'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useSearchStore } from '@/Hooks/useSearchStore'
import { useFetchStore } from '@/Hooks/useFetchStore'

import LogoHandler from '@/Helpers/LogoHandler'
import HeaderLayout from '@/Layout/Header/Header'
import SearchBar from '@/Layout/SearchBar/SearchBar'
import { searchProducts } from '@/Api/products'

import { SadMagnifierIcon } from '@/Helpers/icons'

const PAGE_SIZE = 20

export default function Search() {
  const [currentSearch, setCurrentSearch] = useState('')

  const { addHistory, goHome } = useHistoryStore()
  const { searchTerm, searchType } = useSearchStore()
  const { getFetchDetails } = useFetchStore()

  const { headers, callback } = getFetchDetails()

  const loaderRef = useRef<HTMLDivElement>(null)

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['search', searchTerm],
    queryFn: ({ pageParam = 0 }) =>
      searchProducts(
        searchTerm,
        searchType,
        pageParam,
        PAGE_SIZE,
        headers,
        callback
      ),
    getNextPageParam: (lastPage) =>
      lastPage.result.length === 20 ? lastPage.index + 1 : undefined,
    retry: (failureCount, error) => {
      const errorMessage = error as string
      if (errorMessage.toString().includes('401')) {
        addHistory({ route: 'Blocked' })
        return false
      }
      if (failureCount > 0) return false
      return true
    },
    enabled: !!searchTerm,
    keepPreviousData: true,
  })

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      if (loaderRef.current) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (
              entries[0].isIntersecting &&
              hasNextPage &&
              !isFetchingNextPage
            ) {
              fetchNextPage()
            }
          },
          {
            threshold: 1.0,
          }
        )
        observer.observe(loaderRef.current)
      }
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      if (data && data.pages.length > 0) {
        setCurrentSearch(searchTerm)
      }
      document.getElementById('product-container')?.scrollTo({ top: 0 })
      refetch()
    }
  }, [searchTerm])

  const productSkeleton = () => {
    return (
      <>
        {Array.from({ length: PAGE_SIZE }).map((_, index) => (
          <ProductSkeletonLoading key={index} />
        ))}
      </>
    )
  }

  const emptyResults = () => {
    return (
      <div className="h-full lg:container lg:mx-auto">
        <div className="flex flex-col text-center items-center h-full justify-center">
          <div className="content">
            <SadMagnifierIcon className="h-40 m-auto text-brand-400 fill-stone-200 stroke-brand-200 dark:fill-stone-800 dark:stroke-brand-800 dark:text-brand-400" />
            <div className="mt-4 text-brand-800 dark:text-brand-200 font-medium text-base leading-normal">
              <p>Não encontramos o que você buscou.</p>
              <p>Tente novamente utilizando outros termos.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <HeaderLayout columns={2}>
        <div
          className="flex items-start justify-start w-full max-h-11 max-w-24"
          onClick={goHome}
        >
          <LogoHandler />
        </div>
        <SearchBar />
      </HeaderLayout>
      <div className="content w-full h-full py-16 lg:container lg:mx-auto lg:py-8">
        {isLoading ? (
          <div id="product-container" className="px-4 overflow-y-auto h-full">
            <div
              id="product-list"
              className="grid grid-cols-2 lg:grid-cols-4 gap-x-2 gap-y-4 pb-36"
            >
              {productSkeleton()}
            </div>
          </div>
        ) : status === 'error' ||
          (data &&
            (data.pages.length === 0 || data.pages[0].result.length === 0)) ? (
          emptyResults()
        ) : (
          <>
            <h5 className="p-4 text-stone-700 dark:text-stone-300 text-base font-medium leading-normal">
              Resultados para: "{!searchTerm ? currentSearch : searchTerm}"
            </h5>
            <div id="product-container" className="px-4 overflow-y-auto h-full">
              <div
                id="product-list"
                className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-x-2 gap-y-4 auto-rows-min lg:gap-x-4 lg:gap-y-8 pb-20"
              >
                {data?.pages.map((group, i) => (
                  <React.Fragment key={i}>
                    {group.result.map((item: any) => (
                      <QuickProductAdd item={item} key={item.id} />
                    ))}
                  </React.Fragment>
                ))}
                {isFetchingNextPage ? (
                  productSkeleton()
                ) : hasNextPage ? (
                  <div
                    ref={loaderRef}
                    className="products-scroll h-8 z-50"
                  ></div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <CartButton />
      <ProductDetails />
    </>
  )
}
