import React from 'react';
import { useHistoryStore } from '@/Hooks/useHistory';

import CartButton from '@/Layout/Footer/CartButton';
import AccountButton from '@/Layout/Footer/AccountButton';

enum display {
  'flex',
  'basis'
}

interface IHeaderProps {
  columns: number;
  display?: display|string;
  children: React.ReactNode[];
}

const HeaderLayout: React.FC<IHeaderProps> = ({ columns, display, children }) => {
  const { checkCurrentHistory } = useHistoryStore();

  return (
    <header className="fixed w-screen flex items-center top-0 text-center h-16 py-2 px-4 lg:h-16 xl:h-16 bg-stone-100 dark:bg-stone-900 z-30 lg:max-w-full lg:sticky">
      <div className="w-full lg:container lg:mx-auto lg:px-4">
        <div className="flex flex-row w-full h-11 items-center justify-center align-middle gap-2 lg:container lg:mx-auto lg:gap-8 lg:h-12">
          <div className={`content left shrink-0 ${(display && display === 'basis') ? 'basis-24' : ''}`}>
            {children[0]}
          </div>
          <div className={`content main grow shrink ${(display && display === 'basis') ? 'basis-full' : ''}`}>
            {children[1]}
          </div>
          {(columns === 3 && children[2]) &&
          <div className={`content right shrink-0 ${(display && display === 'basis') ? 'basis-24' : ''}`}>
            {children[2]}
          </div>
          }
          <div className="hidden"></div>
          <div
            className={`hidden text-stone-600 dark:text-stone-300 lg:flex lg:gap-8 ${checkCurrentHistory('Cart') ? 'lg:hidden' : ''}`}
          >
            <AccountButton size="h-6 w-6 cursor-pointer" />
            <CartButton size="h-6 w-6 cursor-pointer" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderLayout;