import React from 'react';
import CategoriesImages from './CategoriesImages';

import { useTokenStore } from '@/Hooks/useTokenStore';
import { useCategoryStore } from "@/Hooks/useCategoryStore";
import { useHistoryStore } from '@/Hooks/useHistory';

interface IProps {
  category: any;
}

export default function Category(props: IProps) {
  const { category } = props
  const { tokenState } = useTokenStore();
  const { setActiveCategory, activeCategory } = useCategoryStore();
  const { addHistory } = useHistoryStore();

  const isActive = activeCategory.link === category.link

  return (
    <div
      className={`
        w-24 flex flex-col justify-center items-start text-stone-600 dark:text-stone-300
        lg:flex-row lg:justify-center lg:items-center lg:text-sm lg:w-full lg:cursor-pointer
        lg:p-2
        ${isActive ? 'bg-brand-100 dark:bg-stone-700 rounded-lg' : ''}
      `}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        setActiveCategory(category)
        addHistory({ route: 'CategoryView' })
      }}
    >
      <div className="category-image-holder w-24 h-12 rounded-lg overflow-hidden bg-brand-100 flex justify-center items-center dark:bg-stone-700 lg:w-16 lg:hidden">
        <CategoriesImages imageUrl={category.image} imageAlt={category.name} cdChannel={tokenState.cdChannel!} />
      </div>
      <div className="w-full text-center mt-1 line-clamp-2 lg:text-left lg:m-0">{category.name}</div>
    </div>
  );
}
