import { create } from 'zustand';
import { useFetchStore } from '@/Hooks/useFetchStore';

type SessionState = {
  sessionInfo: any | {};
  setSessionInfo: () => void;
};

const fetchUrl = useFetchStore.getState().fetchUrl;

export const useSessionStore = create<SessionState>((set, get) => ({
  sessionInfo: {},
  setSessionInfo: async () => {
    const response = await fetchUrl('user/whatsapp', 'GET');
    set(() => ({
      sessionInfo: response
    }));
  },
}));
