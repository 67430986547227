import { create } from 'zustand';
import { ICart } from '@/Interfaces/Cart';

type SearchState = {
  searchTerm: string;
  searchType: string;
  setSearchTerm: (searchTerm: string) => void;
  setSearchType: (searchTerm: string) => void;
  searchResults: ICart[];
  setSearchResults: (searchResults: ICart[]) => void;
};

export const useSearchStore = create<SearchState>((set) => ({
  searchTerm: '',
  searchType: '',
  setSearchTerm: (searchTerm: string) => set({ searchTerm }),
  setSearchType: (searchType: string) => set({ searchType }),
  searchResults: [],
  setSearchResults: (searchResults: ICart[]) => set({ searchResults }),
}));
