import { useEffect } from 'react';
import { useToastStore } from '@/Hooks/useToastStore';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Toast = () => {
  const { messages, removeMessage } = useToastStore();

  const limitedMessages = messages.slice(-3);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (messages.length > 0) {
        removeMessage(messages[0].id);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [messages, removeMessage]);

  const handleClose = (id: string) => {
    removeMessage(id);
  };

  return (
    <>
      { (messages.length > 0) &&
        <div className="toast-container z-30 absolute inset-x-0 px-4 py-2">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`
              toast toast-${message.id} bg-stone-200 dark:bg-stone-800
              text-sm font-medium leading-tight
              rounded-lg p-2 mt-2 flex align-top justify-start items-start
              text-stone-800 dark:text-stone-300 border
              ${message.type === 'success' ? 'border-green-500 dark:border-green-400' : ''}
              ${message.type === 'error' ? 'border-red-500 dark:border-red-400' : ''}
              ${message.type === 'warning' ? 'border-yellow-500 dark:border-yellow-400' : ''}
              animate-fade-in-out-top
            `}
          >
            <div className="toast-message">{message.message}</div>
            <button className="toast-close" onClick={() => handleClose(message.id)}>
              <XMarkIcon className='h-4 w-4' />
            </button>
          </div>
        ))}
        </div>
      }
    </>
  );
};

export default Toast;