import { create } from 'zustand';

type Route = {
  route: string;
  params?: any;
}

type HistoryState = {
  history: Route[];
  addHistory: (history: Route) => void;
  getPreviousHistory: () => Route | undefined;
  goBack: () => void;
  goHome: () => void;
  getCurrentHistory: () => Route | undefined;
  getCurrentParams: () => any | undefined;
  checkCurrentHistory: (area: string) => boolean;
};

export const useHistoryStore = create<HistoryState>((set, get) => ({
  history: [],
  addHistory: (history) => {
    const previousHistory = get().history[get().history.length - 1];
    if (previousHistory && (previousHistory.route === 'Blocked' || previousHistory.route === 'NoToken')) return;
    if (!previousHistory || previousHistory.route !== history.route) {
      set((state) => ({ history: [...state.history, history] }));
    }
  },
  getPreviousHistory: () => {
    const history = get().history;
    return history[history.length - 2];
  },
  goBack: () => {
    const previousHistory = get().getPreviousHistory();
    if (previousHistory) {
      const history = get().history;
      history.pop();
      set(() => ({ history: [...history ] }));
    }
  },
  goHome: () => {
    get().addHistory({ route: 'Home'});
  },
  getCurrentHistory: () => {
    const history = get().history;
    return history[history.length - 1];
  },
  getCurrentParams: () => {
    const history = get().history;
    return history[history.length - 1].params;
  },
  checkCurrentHistory: (area) => {
    const current = get().getCurrentHistory();
    return (current && current.route === area) ? true : false;
  }
}));
