import React from 'react';
import { StarIcon, BuildingStorefrontIcon, LightBulbIcon } from '@heroicons/react/24/outline';

interface IconProps {
  type: string;
  className?: string;
}

const SuggestionIconByType: React.FC<IconProps> = ({ type, className = 'h-6 w-6 text-stone-700 dark:text-stone-200' }) => {
  switch (type) {
    case 'REPOSICAO':
      return <StarIcon className={className} />;
    case 'SIMILARES':
      return <LightBulbIcon className={className} />;
    case 'RESGATE':
      return <BuildingStorefrontIcon className={className} />
    default:
      return <></>;
  }
};

export default SuggestionIconByType;