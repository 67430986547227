import { create } from 'zustand';

type ToastType = 'success' | 'warning' | 'error';

interface ToastMessage {
  id: string;
  type: ToastType;
  message: string;
}

interface ToastStore {
  messages: ToastMessage[];
  addMessage: (type: ToastType, message: string) => void;
  removeMessage: (id: string) => void;
}

const useToastStore = create<ToastStore>((set, get) => ({
  messages: [],
  addMessage: (type, message) => {
    const { messages } = get();
    const id = Math.random().toString(36).substr(2, 9);
    const newMessage = { id, type, message };
    if (messages.length === 0) {
      set({ messages: [newMessage] });
    } else {
      set({ messages: [...messages, newMessage] });
    }
  },
  removeMessage: (id) => {
    const { messages } = get();
    const filteredMessages = messages.filter((message) => message.id !== id);
    if (filteredMessages.length === 0) {
      set({ messages: filteredMessages.slice(1) });
    } else {
      set({ messages: filteredMessages });
    }
  },
}));

export { useToastStore };