import React, { useEffect, useRef } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { ProductSkeletonLoading } from '@/Helpers/skeletonLoading'
import QuickProductAdd from '@/Components/QuickProductAdd/QuickProductAdd'
import Categories from '@/Components/Categories/Categories'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useFetchStore } from '@/Hooks/useFetchStore'
import { useSessionStore } from '@/Hooks/useSessionStore'

import { getSuggestions } from '@/Api/products'
import { useCategoryStore } from '@/Hooks/useCategoryStore'

const PAGE_SIZE = 20

export default function ProductsSuggestions(props: any) {
  const { addHistory, getCurrentParams } = useHistoryStore()
  const { getCustomerDetails } = useFetchStore()
  const { sessionInfo } = useSessionStore()
  const { categories } = useCategoryStore()

  const { customer, headers } = getCustomerDetails()

  const loaderRef = useRef<HTMLDivElement>(null)

  const suggestionParams = getCurrentParams()

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [suggestionParams.display, suggestionParams.type],
    queryFn: ({ pageParam = 0 }) =>
      getSuggestions(
        customer,
        sessionInfo.nrNationalRegistry,
        suggestionParams.type,
        pageParam,
        PAGE_SIZE,
        headers
      ),
    getNextPageParam: (lastPage) =>
      lastPage.result.length === 20 ? lastPage.index + 1 : undefined,
    retry: (failureCount, error) => {
      const errorMessage = error as string
      if (errorMessage.toString().includes('401')) {
        addHistory({ route: 'Blocked' })
        return false
      }
      if (failureCount > 2) return false
      return true
    },
  })

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      if (loaderRef.current) {
        const observer = new IntersectionObserver(
          (entries) => {
            if (
              entries[0].isIntersecting &&
              hasNextPage &&
              !isFetchingNextPage
            ) {
              fetchNextPage()
            }
          },
          {
            threshold: 1.0,
          }
        )
        observer.observe(loaderRef.current)
      }
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage])

  const productSkeleton = () => {
    return (
      <>
        {Array.from({ length: PAGE_SIZE }).map((_, index) => (
          <ProductSkeletonLoading key={index} />
        ))}
      </>
    )
  }

  return (
    <div
      id="product-container"
      className="px-4 overflow-y-auto h-full lg:px-0 lg:flex lg:h-auto lg:overflow-visible"
    >
      <div className="hidden lg:block lg:w-3/12 xl:w-2/12 lg:relative lg:px-4">
        <div className="sticky top-24 h-auto">
          <Categories />
        </div>
      </div>
      <div className="lg:w-9/12 xl:w-10/12 lg:px-4 lg:h-full">
        <div className="hidden w-full font-medium text-2xl text-brand-600 py-4 mb-6 dark:text-brand-400 lg:block lg:border-b lg:border-brand-600 lg:dark:border-stone-800">
          {suggestionParams.category}
        </div>
        <div
          id="product-list"
          className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 gap-x-2 gap-y-4 auto-rows-min lg:gap-x-4 lg:gap-y-8 pb-20"
        >
          {isLoading ? (
            productSkeleton()
          ) : status === 'error' ? (
            <p>Error: </p>
          ) : (
            <>
              {data.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.result.map((item: any) => (
                    <QuickProductAdd item={item} key={item.id} />
                  ))}
                </React.Fragment>
              ))}
              {isFetchingNextPage ? (
                productSkeleton()
              ) : hasNextPage ? (
                <div ref={loaderRef} className="products-scroll h-8 z-50"></div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
