import React from 'react';
import SuggestionIconByType from "@/Helpers/SuggestionIcon";

type TagsLayoutProps = {
  label: string;
  type?: string;
  icon?: string;
}

const TagsLayout: React.FC<TagsLayoutProps> = React.memo((props) => {
  const { label, type, icon } = props;

  const tagType = () => {
    switch (type) {
      case 'icon':
        return (
          <SuggestionIconByType type={icon!} className="h-4 w-4" />
        )
      case 'full':
        return (
          <>
            <SuggestionIconByType type={icon!} className="h-4 w-4 mr-2" />
            { label }
          </>
        )
      case 'text':
      default:
        return (
          <>
            { label }
          </>
        )
    }
  }

  return (
    <div>
      <span className="rounded px-1 py-0.5 bg-brand-500 dark:bg-brand-400 text-white text-xs font-semibold leading-none inline-flex items-center">
        { tagType() }
      </span>
    </div>
  );
});

export default TagsLayout;