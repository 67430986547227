import Category from '@/Components/Categories/Category'

import { useCategoryStore } from '@/Hooks/useCategoryStore'

export default function Categories() {
  const { categories } = useCategoryStore()

  return (
    <>
      <div className="app-categories h-24 py-2 mt-4 lg:relative lg:h-auto lg:py-0 lg:mt-0">
        <div className="scroll flex overflow-x-scroll pb-10 hide-scroll-bar text-xs lg:overflow-initial lg:bg-stone-50 lg:dark:bg-stone-800 lg:rounded-lg lg:py-4 lg:shadow-md lg:shadow-stone-950/10">
          <div className="flex flex-nowrap mx-4 gap-2 items-start lg:flex-col lg:w-full">
            <div className="font-medium text-lg text-brand-600 dark:text-brand-400 hidden lg:block">
              Categorias
            </div>
            {categories.map((category: any) => (
              <div
                className="flex items-start text-xs font-medium justify-center lg:w-full lg:justify-start"
                key={category.link}
              >
                <Category category={category} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
