import { create } from 'zustand'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useFetchStore } from '@/Hooks/useFetchStore'

type CategoryStore = {
  categories: any[]
  activeCategory: Record<string, any>
  setActiveCategory: (category: Record<string, any>) => void
  getCategories: () => Promise<void>
  cleanCategories: () => void
  cleanActiveCategory: () => void
}

const addHistory = useHistoryStore.getState().addHistory
const fetchUrl = useFetchStore.getState().fetchUrl

export const useCategoryStore = create<CategoryStore>((set) => ({
  categories: [],
  activeCategory: {},
  setActiveCategory(category) {
    set(() => ({
      activeCategory: category,
    }))
  },
  async getCategories() {
    const categories = await fetchUrl('ecommerce/categories', 'GET')
    if (categories.status === 200) {
      const categoriesArray = categories.categories.map((category: any) => {
        return {
          name:
            category.charAt(0).toUpperCase() + category.slice(1).toLowerCase(),
          image: `${category
            .normalize('NFD')
            .replace(',', '')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .replace(/\s/gm, '_')
            .replace(',', '')}.png`,
          link: encodeURIComponent(category.toUpperCase()),
        }
      })
      set(() => ({
        categories: categoriesArray,
      }))
    } else {
      addHistory({ route: 'Blocked' })
    }
  },
  cleanCategories() {
    set(() => ({
      categories: [],
    }))
  },
  cleanActiveCategory() {
    set(() => ({
      activeCategory: {},
    }))
  },
}))
