import { create } from 'zustand';

import { useHeaderStore } from '@/Hooks/useHeaderStore';
import { useTokenStore } from '@/Hooks/useTokenStore';

type FetchState = {
  fetchUrl: (endpoint: string, method: string, body?: {}) => Promise<any>;
  getFetchDetails: () => any;
  getCustomerDetails: () => any;
};

export const useFetchStore = create<FetchState>((set) => ({
  fetchUrl: async (endpoint, method, body) => {
    const headers = useHeaderStore.getState().headers;
    const tokenState = useTokenStore.getState().tokenState;

    const response = await fetch(`${tokenState.callBack}/${endpoint}`, {
      method: method,
      headers: { ...headers },
      body: (body) ? JSON.stringify(body) : null
    });

    const fetchResponse = await response.json();
    return {status: response.status, ...fetchResponse};
  },
  getFetchDetails: () => {
    const headers = useHeaderStore.getState().headers;
    const callback = useTokenStore.getState().tokenState.callBack;

    return { headers, callback };
  },
  getCustomerDetails: () => {
    const customer = useTokenStore.getState().tokenState.customerCallback;
    const headers = useHeaderStore.getState().headers;

    return { customer, headers };
  }
}));
