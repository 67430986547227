import { LoadingIndicator } from '@/Helpers/icons'

import { useLoadingStore } from '@/Hooks/useLoadingStore'
import { useHistoryStore } from '@/Hooks/useHistory';

export default function Loading() {
  const { isLoading } = useLoadingStore()
  const { getCurrentHistory } = useHistoryStore()

  return(
    <>
      <div className={`relative z-30 overflow-hidden ${isLoading ? 'visible lg:block' : 'invisible lg:hidden'}`}>
        <div className={`overscroll-none backdrop fixed h-screen w-screen top-0 inset-x-0 backdrop-blur-sm bg-stone-100/30 dark:bg-stone-800/30 ${isLoading ? 'visible' : 'invisible'}`}></div>

        <div className={`overscroll-none flex-col fixed h-screen w-screen top-0 inset-x-0 loading-container rounded-full m-auto flex items-center z-40 ${isLoading ? 'visible' : 'invisible'} `}>
          <div className='rounded-full m-auto flex items-center flex-col'>
            <LoadingIndicator className='stroke-brand-500 dark:stroke-brand-400 h-32 w-32 animate-spin' />
            {
              (getCurrentHistory()!.route === 'Cart' || getCurrentHistory()!.route === 'Checkout') &&
              <div className='text-stone-900 text-base font-semibold leading-normal dark:text-stone-400 mx-auto w-5/6 text-center p-4 bg-stone-100 dark:bg-stone-900 rounded-lg shadow-lg'>
                Estamos atualizando seu carrinho, por favor aguarde...
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}