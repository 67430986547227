import React, { useState, useRef, useEffect } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useSearchStore } from '@/Hooks/useSearchStore';
import { useHistoryStore } from '@/Hooks/useHistory';

const SearchBar: React.FC = () => {
  const[showClear, setShowClear] = useState(false);
  const { searchTerm, setSearchTerm, searchType, setSearchType } = useSearchStore();
  const { addHistory } = useHistoryStore();

  const searchRef = useRef<HTMLInputElement>(null);

  const displayCleanButton = (ev: any) => {
    setShowClear(!!ev.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(event);
    }
  };

  const handleClear = () => {
    const searchInput = document.getElementById('search-input') as HTMLInputElement;
    searchInput.value = '';
    setSearchTerm('');
    setShowClear(false);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    const eanRegex = /^\d{13}$/;
    const search = e.target.value;
    if (!search.length && searchTerm.length) {
      setSearchTerm('');
      return;
    }
    if (!search.length) return;
    if (eanRegex.test(search)) {
      setSearchTerm(search);
      setSearchType('ean');
      return;
    }
    setSearchTerm(search);
    setSearchType('name');
    addHistory({route: 'Search'});
    return;
  }

  return (
    <div className="flex w-full items-center h-11 relative lg:w-8/12 lg:mx-auto lg:h-12">
      <form action="get" className="search w-full">
        <input
          id="search-input"
          ref={searchRef}
          type="search"
          className="appearance-none caret-cta dark:caret-cta-light outline-none h-full w-full border bg-stone-50 border-stone-300 rounded-lg placeholder:text-stone-400 text-stone-700 text-sm p-3 pl-8 pr-6 focus:ring-2 focus:ring-inset focus:ring-offset-2 focus:ring-brand-500  dark:bg-stone-800 dark:border-stone-700 dark:placeholder:text-stone-500 dark:text-stone-300 dark:ring-offset-stone-900 dark:focus:ring-offset-stone-800 dark:focus:ring-cta-light search-cancel:appearance-none"
          placeholder="Buscar produtos"
          onKeyDown={handleKeyDown}
          onChange={displayCleanButton}
        />
        <div className="absolute inset-y-0 left-2 items-center flex flex-row">
          <MagnifyingGlassIcon className="h-5 w-5 text-stone-400 dark:text-stone-500" />
        </div>
        { showClear &&
          <div className="absolute inset-y-0 right-2 items-center flex flex-row">
            <button className='h-4 w-4' onClick={handleClear}>
              <XMarkIcon className="h-4 w-4 stroke-4 text-brand-400 dark:text-brand-500" />
            </button>
          </div>
        }
      </form>
    </div>
  );
};

export default SearchBar;