import { useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

import { useTokenStore } from '@/Hooks/useTokenStore';

export default function FinalizeOrder() {
  // const { finalizedOrder } = useContext(CartContext);
  const { tokenState } = useTokenStore();
  const whatsappLink = (tokenState.callBackChannelPhone) ? `whatsapp://send?phone=${tokenState.callBackChannelPhone}` : 'whatsapp://app';

  const closeWindow = () => {
    setTimeout(() => {
      window.open('', '_self', '');
      window.location.replace(whatsappLink);
    }, 1500);
  };

  useEffect(() => {
    closeWindow();
  }, []);

  return (
    <>
    <div className={`absolute inset-0 z-50 bg-stone-100 dark:bg-stone-900 overscroll-none h-screen touch-none alert-window show transition-opacity ease-in duration-700 opacity-100`} data-testid="alert-window" >
        <div className="details overscroll-none touch-none select-none h-full flex flex-col justify-center items-center">
          <div className="px-12 m-auto text-center align-middle">
            <div className='bg-brand-100 dark:bg-stone-800 dark:border-stone-800 rounded-full border-8 border-brand-100 h-36 w-36 m-auto flex items-center'>
              <CheckIcon className='h-32 w-32 stroke-brand-500 dark:stroke-brand-400' />
            </div>
            <h3 className="text-brand-800 dark:text-brand-300 font-semibold text-lg leading-7 mt-8">
              Pedido enviado!
            </h3>
            <h5 className='text-brand-500 dark:text-stone-500 mt-1 text-sm font-medium leading-tight'>
              O processo continuará via chat.<br />
              Clique <a href={whatsappLink} target='_self'>aqui</a> caso não seja redirecionado.
            </h5>
          </div>
        </div>
      </div>
      <div className={`backdrop visible h-screen absolute`}></div>
    </>
  );
}
