import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import { ICart } from '@/Interfaces/Cart'
import { PlusIcon, MinusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { CartContext } from '@/Contexts/CartContext'
import { NumericFormat } from 'react-number-format'
import { useCartStore } from '@/Hooks/useCartStore'
import { useHistoryStore } from '@/Hooks/useHistory'

type QuickAddProps = {
  item: ICart
  type?: string
}

const QuickAddLayout: React.FC<QuickAddProps> = React.memo((props) => {
  const { item, type } = props
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const [clearItem, setClearItem] = useState(false)
  const { cartItems, addToCart, updateCart, removeFromCart, getOnCartItem } =
    useCartStore()
  const { sendAlertMessage, setDetailedItem, layoutVars, detailedItem } =
    useContext(CartContext)
  const { getCurrentHistory } = useHistoryStore()

  const onCart = !!getOnCartItem(item.id) ? getOnCartItem(item.id) : false
  const minimunQuantity = item.minimunQuantity ? item.minimunQuantity : 1
  const isSuggestion =
    item.attributes && item.attributes.suggestion ? true : false
  const suggestedQty =
    item.attributes &&
    item.attributes.suggestion &&
    item.attributes.suggestion.suggestedQty
      ? item.attributes.suggestion.suggestedQty
      : 1

  const handleOnBlur = (ev: any) => {
    ev.preventDefault()
    if (clearItem) {
      setQuantity(0)
      ev.target.value = 0
    }
    if (quantity > parseFloat(item.stockQuantity)) {
      return setQuantity(parseFloat(item.stockQuantity))
    }
    if (ev.target.value > 0) {
      if (minimunQuantity && ev.target.value < minimunQuantity) {
        // sendAlertMessage({type: 'warning', message: `A quantidade mínima para o item ${item.name} é de: ${item.minimunQuantity}.`});
        return setQuantity(minimunQuantity)
      }
    }
  }
  const handleOnFocus = (ev: any) => {
    ev.preventDefault()
    const end = ev.target.value.length
    ev.target.setSelectionRange(end, end)
    if (ev.target.value === '0') {
      ev.target.value = ''
    }
  }

  const handleOnChange = (ev: any, sourceInfo: any) => {
    setClearItem(false)
    if (sourceInfo.source === 'prop') return
    if (!ev.value) {
      setClearItem(true)
      setQuantity(null!)
      return
    }
    if (ev.value > parseFloat(item.stockQuantity)) {
      return setQuantity(parseFloat(item.stockQuantity))
    }
    if (parseFloat(ev.value) >= 0) {
      return setQuantity(parseFloat(ev.value))
    }
    return
  }

  const addQuantity = () => {
    if (quantity < parseFloat(item.stockQuantity)) {
      addToCart(item, minimunQuantity)
    }
  }
  const reduceQuantity = () => {
    if (type === 'cart')
      return item.quantity - minimunQuantity > 0
        ? addToCart(item, -minimunQuantity)
        : removeFromCart(item)
    return item.quantity - minimunQuantity > 0
      ? addToCart(item, -minimunQuantity)
      : removeFromCart(item)
  }

  useEffect(() => {
    if (onCart) {
      setQuantity(onCart.quantity)
    } else {
      setQuantity(item.quantity)
    }
  }, [item.quantity, onCart])

  return (
    <div className="h-9 p-2 rounded-lg justify-between items-center gap-1 inline-flex bg-stone-50 dark:bg-stone-800 text-cta overflow-hidden ring-1 ring-inset ring-cta dark:ring-cta-light dark:text-cta-light">
      <button
        disabled={quantity === 0 ? true : false}
        className="btn h-5 w-5 disabled:text-stone-300"
        onClick={reduceQuantity}
      >
        {quantity <= 1 || quantity === minimunQuantity ? (
          <TrashIcon className="h-5 w-5" />
        ) : (
          <MinusIcon className="h-5 w-5" />
        )}
      </button>
      <NumericFormat
        value={quantity}
        displayType={'input'}
        allowNegative={false}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={
          layoutVars.products && layoutVars.products.decimalPlaces
            ? layoutVars.products.decimalPlaces
            : 2
        }
        readOnly={
          layoutVars.products && layoutVars.products.blockQuantityInput
            ? layoutVars.products.blockQuantityInput
            : false
        }
        isAllowed={(values: any) => {
          const { floatValue } = values
          return floatValue >= 0 && floatValue <= parseFloat(item.stockQuantity)
        }}
        suffix={
          layoutVars.products && layoutVars.products.pickingOnQty
            ? ` ${item.picking}`
            : ''
        }
        inputMode="decimal"
        className="text-sm text-center outline-none font-semibold bg-stone-50 grow h-5 align-middle indent-0 dark:bg-stone-800 w-4/5 max-w-fit"
        onValueChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
      />
      <button
        className="btn h-5 w-5 disabled:text-stone-300"
        disabled={quantity >= parseFloat(item.stockQuantity) ? true : false}
        onClick={addQuantity}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
    </div>
  )
})

export default QuickAddLayout
