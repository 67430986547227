import { useContext, useState, useEffect } from 'react'
import { NumericFormat } from 'react-number-format'
import { CartContext } from '@/Contexts/CartContext'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useCartStore } from '@/Hooks/useCartStore'
import { useLoadingStore } from '@/Hooks/useLoadingStore'

import TotalizerDetails from '@/Components/Totalizer/Totalizer'

type CheckoutButtonProps = {
  type: string
  disabled?: boolean
}

export default function CheckoutButton(props: CheckoutButtonProps) {
  const { type, disabled } = props
  const [openDetails, setOpenDetails] = useState(false)
  const addHistory = useHistoryStore((state) => state.addHistory)
  const { cartResponse, hasPayment, hasShipping, isFinalized, finalizeOrder } =
    useCartStore()
  const { isLoading } = useLoadingStore()
  const { layoutVars } = useContext(CartContext)

  useEffect(() => {
    if (isFinalized) {
      addHistory({ route: 'Finalize' })
    }
  }, [isFinalized])

  const currencyValues = (value: any) => {
    return (
      <NumericFormat
        value={value}
        displayType={'text'}
        prefix={'R$ '}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator="."
        decimalSeparator=","
      />
    )
  }

  return (
    <>
      <footer
        className="
          fixed inset-x-0 bottom-0 shadow
          bg-stone-50 dark:bg-stone-800
          z-30 px-4 py-4 rounded-t-lg
          lg:w-3/12 lg:rounded-lg lg:sticky lg:top-32 lg:z-auto lg:h-fit
        "
      >
        <div
          className="w-full h-10 mb-2 py-2 inline-flex justify-between"
          onClick={() => setOpenDetails(!openDetails)}
        >
          <div className="inline-flex">
            {layoutVars.cart.minimumPurchase &&
            cartResponse.cart.minimumOrderAmount > 0 ? (
              <div
                className={`inline-flex px-1 py-0.5 rounded text-xs font-semibold leading-none items-center ${
                  cartResponse.cart.minimumOrderAmountReached
                    ? 'text-green-800 bg-green-50 dark:bg-green-900 dark:text-green-50'
                    : 'text-red-800 bg-red-50 dark:bg-red-900 dark:text-red-50'
                }`}
              >
                <span>
                  {cartResponse.cart.minimumOrderAmountReached ? (
                    <CheckIcon className="h-4 w-4 mr-2" />
                  ) : (
                    <XMarkIcon className="h-4 w-4 mr-2" />
                  )}
                </span>
                <span>
                  Pedido mínimo:{' '}
                  {currencyValues(cartResponse.cart.minimumOrderAmount)}
                </span>
              </div>
            ) : (
              <div
                className={`inline-flex px-1 py-0.5 rounded text-xs font-semibold leading-none items-center text-stone-700 dark:text-stone-200`}
              >
                <span>
                  {layoutVars.cart && layoutVars.cart.renameTotalizers
                    ? layoutVars.cart.totalizersLabel
                    : 'Detalhes do pedido'}
                </span>
              </div>
            )}
          </div>
          <span className="lg:hidden">
            {openDetails ? (
              <ChevronUpIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" />
            ) : (
              <ChevronDownIcon className="h-6 w-6 text-stone-700 dark:text-stone-200" />
            )}
          </span>
        </div>
        <div
          className={`
            w-full px-4 py-2 grid grid-cols-1 gap-2 justify-between
            text-sm font-normal leading-tight text-stone-700
            dark:text-stone-200 transition-all duration-500 ease-in-out
            ${
              openDetails
                ? 'visible opacity-100 translate-y-0 lg:block'
                : 'translate-y-full opacity-0 invisible lg:opacity-100 hidden lg:block lg:visible lg:translate-y-0'
            }
          `}
        >
          {layoutVars.cart && layoutVars.cart.totalizers && (
            <TotalizerDetails />
          )}
          {layoutVars.cart.subTotal && cartResponse.cart.subtotal && (
            <div className="w-full h-9 inline-flex justify-between items-center">
              <span>Subtotal</span>
              <span>{currencyValues(cartResponse.cart.subtotal)}</span>
            </div>
          )}
          {cartResponse.cart.shipping &&
            cartResponse.cart.shipping.chosenShipping &&
            cartResponse.cart.freightTotal && (
              <div className="w-full h-9 inline-flex justify-between items-center">
                <span>Frete</span>
                <span>{currencyValues(cartResponse.cart.freightTotal)}</span>
              </div>
            )}
          {layoutVars.cart.couponDiscount &&
            cartResponse.cart.totalDiscountWithCoupon && (
              <div className="w-full h-9 inline-flex justify-between items-center text-red-700 dark:text-red-300">
                <span>Cupom</span>
                <span>
                  {currencyValues(cartResponse.cart.totalDiscountWithCoupon)}
                </span>
              </div>
            )}
          {layoutVars.cart.discount &&
            cartResponse.cart.subtotal - cartResponse.cart.total > 0 && (
              <div className="w-full h-9 inline-flex justify-between items-center text-red-700 dark:text-red-300">
                <span>Descontos</span>
                <span>
                  -{' '}
                  {currencyValues(
                    cartResponse.cart.subtotal - cartResponse.cart.total
                  )}
                </span>
              </div>
            )}
        </div>
        {layoutVars.cart.price && (
          <div className="w-full h-10 mb-2 px-4 py-2 inline-flex justify-between items-center gap-1 rounded bg-stone-100 dark:bg-stone-900 text-stone-700 dark:text-stone-200 text-base font-semibold leading-normal">
            <span>Total</span>
            <span>{currencyValues(cartResponse.cart.total)}</span>
          </div>
        )}
        <button
          className="w-full text-white font-semibold text-lg leading-7 rounded-lg bg-cta dark:bg-cta-light disabled:bg-cta-light disabled:grayscale-50 disabled:saturate-50 disabled:text-stone-300 px-4 py-2"
          onClick={() =>
            type === 'cart'
              ? addHistory({ route: 'Checkout' })
              : finalizeOrder()
          }
          disabled={
            isLoading ||
            (type === 'checkout' && !disabled) ||
            !cartResponse.valid ||
            !hasPayment ||
            !hasShipping ||
            (layoutVars.cart.minimumPurchase &&
              !cartResponse.cart.minimumOrderAmountReached)
          }
        >
          {type === 'cart' ? 'Continuar' : 'Confirmar pedido'}
        </button>
      </footer>
    </>
  )
}
