import LogoHandler from '@/Helpers/LogoHandler';
import SearchBar from '@/Layout/SearchBar/SearchBar';
import HeaderLayout from '@/Layout/Header/Header'

import { useStore } from 'zustand';
import { useHistoryStore } from '@/Hooks/useHistory';

export default function Header() {
  const { goHome } = useStore(useHistoryStore);

  return (
    <HeaderLayout columns={2}>
      <div className="flex items-start justify-start w-full max-h-11 max-w-24 lg:max-w-max lg:max-h-40 cursor-pointer" onClick={goHome}>
        <LogoHandler />
      </div>
      <SearchBar />
    </HeaderLayout>
  );
}
