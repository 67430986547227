import { useState } from 'react';

export default function ConfirmDialog(props: { title: string; message: string; confirmText: string; onConfirm: () => void; cancelText: string ; onCancel: () => void; open: boolean; onOpen: () => void }) {
  const { title, message, confirmText, onConfirm, cancelText, onCancel, open, onOpen } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel();
  };

  return (
    <>
      {open && (
        <>
          <div className="relative z-40" onClick={handleCancel}>
            <div className="fixed inset-0 bg-stone-500/70 backdrop-blur-sm z-10 dark:bg-stone-800/70" onClick={handleCancel} />
            <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
              <dialog open className="bg-stone-100 rounded-lg shadow-lg z-50 inset-0 px-4 py-3 pt-6 w-11/12 dark:bg-stone-900 lg:w-6/12 xl:w-3/12">
                <div className='text-stone-900 text-base font-semibold leading-normal dark:text-stone-400'>{title}</div>
                <div className='text-stone-800 text-base font-normal leading-normal mt-4 dark:text-stone-300'>{message}</div>
                <div className="grid gap-2 grid-cols-2 mt-4">
                  <button onClick={handleConfirm} className="px-4 py-2 rounded-lg ring-1 ring-inset ring-cta text-cta dark:ring-cta-light dark:text-cta-light text-lg text-center outline-none font-semibold">
                    {confirmText}
                  </button>
                  <button onClick={handleCancel} className="px-4 py-2 rounded-lg bg-cta w-full text-lg text-white dark:bg-cta-light">
                    {cancelText}
                  </button>
                </div>
              </dialog>
            </div>
          </div>
        </>
      )}
    </>
  );
}