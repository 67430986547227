import { useContext, useEffect, useState } from 'react'
import { CartContext } from '@/Contexts/CartContext'
import HeaderLayout from '@/Layout/Header/Header'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  TicketIcon,
  MapPinIcon,
  DocumentTextIcon,
  TruckIcon,
  BanknotesIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useCartStore } from '@/Hooks/useCartStore'
import { useSessionStore } from '@/Hooks/useSessionStore'
import CheckoutButton from '@/Components/Checkout/CheckoutButton'
import { useEffectOnce } from 'react-use'

export default function Checkout() {
  const [addCoupon, setAddCoupon] = useState(false)
  const [formFields, setFormFields] = useState<{ [key: string]: string }>({})
  const [disabledFormFields, setDisabledFormFields] = useState<{
    [key: string]: string
  }>({})
  const [isCheckoutEnabled, setIsCheckoutEnabled] = useState(false)

  const goBack = useHistoryStore((state) => state.goBack)
  const {
    cartResponse,
    idOrder,
    setIdOrder,
    setCoupon,
    removeCoupon,
    setShippingMethod,
    setPaymentMethod,
  } = useCartStore()
  const { sessionInfo, setSessionInfo } = useSessionStore()

  const { layoutVars } = useContext(CartContext)

  const hasPaymentSet =
    cartResponse.cart && cartResponse.cart.payment
      ? !!cartResponse.cart.payment.chosenPayment
      : false
  const hasShippingSet =
    cartResponse.cart && cartResponse.cart.shipping
      ? !!cartResponse.cart.shipping.chosenShipping
      : false

  const updateFormFields = (e: any) => {
    const { name, value } = e.target
    setFormFields({
      ...formFields,
      [name]: value,
    })
  }

  useEffectOnce(() => {
    if (layoutVars && layoutVars.cart) {
      if (layoutVars.cart.shipping) {
        setFormFields({
          ...formFields,
          shippingMethod: '',
        })
      }
      if (layoutVars.cart.paymentType) {
        setFormFields({
          ...formFields,
          paymentMethod: '',
        })
      }
    }
  })

  useEffect(() => {
    const areAllFieldsValid = Object.values(formFields).every(
      (value) => value !== ''
    )
    setIsCheckoutEnabled(areAllFieldsValid)
  }, [formFields])

  const formatShippingMethods = () => {
    if (!cartResponse.cart.shippingMethods) return
    if (cartResponse.cart.shippingMethods.length) {
      return cartResponse.cart.shippingMethods.map((type: any) => {
        return type.shippingOptionsPerType.map((option: any) => {
          const value = JSON.stringify({
            shippingKey: option.shippingKey,
            shippingType: option.shippingType,
          })
          return (
            <option key={option.shippingKey} value={value}>
              {option.shippingName}
            </option>
          )
        })
      })
    }
  }

  const formatPaymentMethod = () => {
    if (!cartResponse.cart.paymentMethods) return
    if (cartResponse.cart.paymentMethods.length) {
      const paymentOptions: any[] = cartResponse.cart.paymentMethods.flatMap(
        (type: any) =>
          type.paymentOptionsPerType.flatMap((option: any) => {
            const value = JSON.stringify({
              paymentKey: option.paymentKey,
              paymentType: option.paymentType,
              deliveryId: cartResponse.cart.deliveryId,
            })
            return (
              <option key={option.paymentKey} value={value}>
                {option.paymentName}
              </option>
            )
          })
      )
      return paymentOptions
    }
  }

  useEffectOnce(() => {
    if (hasPaymentSet) {
      const defaultPaymentOption = JSON.stringify({
        paymentKey: cartResponse.cart.payment.chosenPayment.paymentKey,
        paymentType: cartResponse.cart.payment.chosenPayment.paymentType,
        deliveryId: cartResponse.cart.deliveryId,
      })

      setFormFields({
        ...formFields,
        paymentMethod: defaultPaymentOption,
      })
    }
  })

  const defaultShippingMethod = () => {
    if (hasShippingSet) {
      return JSON.stringify({
        shippingKey: cartResponse.cart.shipping.chosenShipping.shippingKey,
        shippingType: cartResponse.cart.shipping.chosenShipping.shippingType,
      })
    }
    return ''
  }

  const handlePaymentMethod = (e: any) => {
    e.preventDefault()
    const paymentMethod = e.target.value
    if (paymentMethod === '') return
    setPaymentMethod(JSON.parse(paymentMethod))
    updateFormFields(e)
  }

  const handleCouponSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleCoupon(e)
    }
  }

  const handleCoupon = (e: any) => {
    e.preventDefault()
    const couponInput = document.getElementById(
      'coupon-input'
    ) as HTMLInputElement
    if (!couponInput.value) return
    setCoupon(couponInput.value)
    couponInput.value = ''
  }

  const handleIdOrder = (e: any) => {
    e.preventDefault()
    setIdOrder(e.target.value)
  }

  useEffect(() => {
    if (!sessionInfo || Object.keys(sessionInfo).length === 0) {
      setSessionInfo()
    }
  }, [sessionInfo, setSessionInfo])

  return (
    <>
      <HeaderLayout columns={3}>
        <ChevronLeftIcon
          className="h-6 w-6 text-stone-700 dark:text-stone-200"
          onClick={goBack}
        />
        <h5 className="w-full flex-1 line-clamp-1 text-base leading-tight font-semibold text-stone-700 dark:text-stone-200">
          Carrinho
        </h5>
        <div className="w-6 h-6" />
      </HeaderLayout>
      <div className="content h-full pt-16 pb-36 overflow-y-auto lg:container lg:mx-auto lg:flex">
        <div className="mt-3 mb-14 grid gap-6 grid-cols-1 px-4 lg:w-9/12">
          {sessionInfo && layoutVars && layoutVars.cart.address && (
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch justify-between items-center gap-1 inline-flex">
                  <div className="text-stone-700 dark:text-stone-300 text-sm font-semibold leading-tight">
                    Endereço de entrega
                  </div>
                  {(sessionInfo && sessionInfo.store.length) > 1 && (
                    <div className="text-center text-brand-600 dark:text-brand-400 text-xs font-semibold leading-none">
                      Trocar
                    </div>
                  )}
                </div>
                <div className="self-stretch p-3 bg-stone-50 rounded-lg border border-stone-300 dark:bg-stone-800 dark:border-stone-700 justify-between items-center gap-2 inline-flex">
                  <div className="w-5 h-5 justify-center items-center flex">
                    <MapPinIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                  </div>
                  <div className="grow shrink basis-0 text-stone-700 dark:text-stone-300 text-base font-normal leading-normal capitalize ">
                    {sessionInfo.store[0].address.toLowerCase()}
                  </div>
                </div>
              </div>
            </div>
          )}

          {layoutVars && layoutVars.cart.shipping && (
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch justify-start items-center gap-1 inline-flex">
                  <label
                    htmlFor="coupon-input"
                    className="text-stone-700 dark:text-stone-300 text-sm font-semibold leading-tight"
                  >
                    Forma de entrega
                  </label>
                </div>
                <div className="self-stretch relative h-12">
                  <span className="absolute inset-y-0 left-2 items-center flex flex-row pointer-events-none">
                    <TruckIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                  </span>
                  <span className="absolute inset-y-0 right-2 items-center flex flex-row pointer-events-none">
                    <ChevronDownIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                  </span>
                  <select
                    name="shippingMethod"
                    id="shippingMethod"
                    defaultValue={defaultShippingMethod()}
                    className="appearance-none outline-none h-full w-full border bg-stone-50 border-stone-300 rounded-lg text-stone-700 text-sm p-3 px-8 focus:ring-2 focus:ring-inset focus:ring-offset-2 focus:ring-brand-500  dark:bg-stone-800 dark:border-stone-700 dark:text-stone-300 dark:ring-offset-stone-900 dark:focus:ring-offset-stone-800 dark:focus:ring-cta-light"
                  >
                    <option value="" disabled className="w-full">
                      Selecione a forma de entrega
                    </option>
                    {formatShippingMethods()}
                  </select>
                </div>
              </div>
            </div>
          )}

          {layoutVars && layoutVars.cart.paymentType && (
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch justify-start items-center gap-1 inline-flex">
                  <label
                    htmlFor="coupon-input"
                    className="text-stone-700 dark:text-stone-300 text-sm font-semibold leading-tight"
                  >
                    Forma de pagamento
                  </label>
                </div>
                <div className="self-stretch relative h-12">
                  <span className="absolute inset-y-0 left-2 items-center flex flex-row pointer-events-none">
                    <BanknotesIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                  </span>
                  <span className="absolute inset-y-0 right-2 items-center flex flex-row pointer-events-none">
                    <ChevronDownIcon className="h-5 w-5 text-stone-600 dark:text-stone-400" />
                  </span>
                  <select
                    name="paymentMethod"
                    id="paymentMethod"
                    defaultValue={formFields.paymentMethod}
                    onChange={handlePaymentMethod}
                    disabled={!!disabledFormFields.paymentMethod}
                    className="appearance-none outline-none h-full w-full border bg-stone-50 border-stone-300 rounded-lg text-stone-700 text-sm p-3 px-8 focus:ring-2 focus:ring-inset focus:ring-offset-2 focus:ring-brand-500  dark:bg-stone-800 dark:border-stone-700 dark:text-stone-300 dark:ring-offset-stone-900 dark:focus:ring-offset-stone-800 dark:focus:ring-cta-light"
                  >
                    <option value="" disabled>
                      Selecione a forma de pagamento
                    </option>
                    {formatPaymentMethod()}
                  </select>
                </div>
              </div>
            </div>
          )}

          {layoutVars && layoutVars.cart.couponDiscount && (
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label
                  htmlFor="coupon-input"
                  className="text-stone-700 dark:text-stone-300 text-sm font-semibold leading-tight"
                >
                  Cupom de desconto
                </label>
                <div className="self-stretch relative h-12">
                  <span className="absolute inset-y-0 left-2 items-center flex flex-row pointer-events-none">
                    <TicketIcon className="h-5 w-5 text-stone-400 dark:text-stone-500" />
                  </span>
                  <input
                    id="coupon-input"
                    type="search"
                    className="appearance-none caret-cta dark:caret-cta-light outline-none h-full w-full border bg-stone-50 border-stone-300 rounded-lg placeholder:text-stone-400 text-stone-700 text-sm p-3 pl-8 pr-12 focus:ring-2 focus:ring-inset focus:ring-offset-2 focus:ring-brand-500  dark:bg-stone-800 dark:border-stone-700 dark:placeholder:text-stone-500 dark:text-stone-300 dark:ring-offset-stone-900 dark:focus:ring-offset-stone-800 dark:focus:ring-cta-light search-cancel:appearance-none"
                    placeholder="Adicionar cupom de desconto"
                    value={
                      cartResponse.cart.couponApplied
                        ? cartResponse.cart.couponApplied
                        : ''
                    }
                    onKeyDown={handleCouponSubmit}
                    onInput={(event) => {
                      setAddCoupon(
                        (event.target as HTMLInputElement).value.length > 0
                      )
                    }}
                  />
                  <span className="absolute inset-y-0 right-2 items-center flex flex-row">
                    {cartResponse.cart.couponApplied ? (
                      <XMarkIcon
                        className="h-5 w-5 text-stone-400 dark:text-stone-500"
                        onClick={removeCoupon}
                      />
                    ) : addCoupon ? (
                      <button
                        onClick={handleCoupon}
                        className="text-brand-500 dark:text-brand-400 font-bold rounded border border-brand-500 dark:border-brand-400 px-2 py-1 text-xs"
                      >
                        OK
                      </button>
                    ) : (
                      <></>
                    )}
                  </span>
                </div>
                <div className="text-red-600 dark:text-red-400 text-xs font-normal">
                  Cupom inválido
                </div>
              </div>
            </div>
          )}

          {layoutVars && layoutVars.cart.idOrder && (
            <div className="w-full flex-col justify-start items-start inline-flex">
              <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                <label
                  htmlFor="coupon-input"
                  className="text-stone-700 dark:text-stone-300 text-sm font-semibold leading-tight"
                >
                  Número pedido cliente
                </label>
                <div className="self-stretch relative h-12">
                  <span className="absolute inset-y-0 left-2 items-center flex flex-row pointer-events-none">
                    <DocumentTextIcon className="h-5 w-5 text-stone-400 dark:text-stone-500" />
                  </span>
                  <input
                    id="coupon-input"
                    type="search"
                    className="appearance-none caret-cta dark:caret-cta-light outline-none h-full w-full border bg-stone-50 border-stone-300 rounded-lg placeholder:text-stone-400 text-stone-700 text-sm p-3 pl-8 pr-6 focus:ring-2 focus:ring-inset focus:ring-offset-2 focus:ring-brand-500  dark:bg-stone-800 dark:border-stone-700 dark:placeholder:text-stone-500 dark:text-stone-300 dark:ring-offset-stone-900 dark:focus:ring-offset-stone-800 dark:focus:ring-cta-light search-cancel:appearance-none"
                    placeholder="Número pedido cliente"
                    value={idOrder}
                    onChange={handleIdOrder}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <CheckoutButton type="checkout" disabled={isCheckoutEnabled} />
      </div>
    </>
  )
}
