import { get } from "lodash";

const formatProducts = (products: []) => {
  return products.map((product: any) => {
    const newItem = {
      ...product,
      id: product.id ? product.id : product.cdSku,
      name: product.dsSku,
      image: product.images ? product.images['200'] : null,
    };
    return newItem;
  });
};

export const getSuggestions = async (callback: string, nrNationalRegistry: string, suggestedType: string, page: number, perPage: number, headers: any) => {
  const response = await fetch(
    `${callback}/suggestion?nrNationalRegistry=${nrNationalRegistry}&perPage=${perPage}&index=${page}&type=1&suggestedType=${suggestedType}`,
    {
      method: 'GET',
      headers: { ...headers},
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.status}`);
  }

  if (response.status === 204) {
    return { result: [] };
  }
  const data = await response.json();

  data.result = formatProducts(data.result);

  return data;
}

export const getProducts = async (category: any, page: number, perPage: number, headers: any, callback: string) => {
  const response = await fetch(
    `${callback}/ecommerce/products/?index=${page}&perPage=${perPage}&category=${category}`,
    {
      method: 'GET',
      headers: { ...headers},
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.status}`);
  }

  const data = await response.json();

  data.result = formatProducts(data.result);

  return data;
};

export const getProductsBySku = async (sku: string, page: number, perPage: number, headers: any, callback: string) => {
  const response = await fetch(
    `${callback}/ecommerce/products/?index=${page}&perPage=${perPage}&cdSku=${sku}`,
    {
      method: 'GET',
      headers: { ...headers},
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.status}`);
  }

  const data = await response.json();

  data.result = formatProducts(data.result);

  return data;
}

export const getProductsByEan = async (ean: string, page: number, perPage: number, headers: any, callback: string) => {
  const response = await fetch(
    `${callback}/ecommerce/products/?index=${page}&perPage=${perPage}&cdEan=${ean}`,
    {
      method: 'GET',
      headers: { ...headers},
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.status}`);
  }

  const data = await response.json();

  data.result = formatProducts(data.result);

  return data;
}

export const getProductsByName = async (name: string, page: number, perPage: number, headers: any, callback: string) => {
  const response = await fetch(
    `${callback}/ecommerce/products/?index=${page}&perPage=${perPage}&dsSku=${encodeURIComponent(name.normalize('NFD').replace(/[\u0300-\u036f]/g, ""))}`,
    {
      method: 'GET',
      headers: { ...headers},
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch products: ${response.status}`);
  }

  const data = await response.json();

  if (data.result.length === 0) {
    return getProductsBySku(name, page, perPage, headers, callback);
  }

  data.result = formatProducts(data.result);

  return data;
}

export const searchProducts: any = async (searchParam: string, searchType: string,  page: number, perPage: number, headers: any, callback: string) => {
  if (!searchParam) {
    throw new Error(`Missing searchParam: 500`);
  }

  switch (searchType) {
    case 'ean':
      return getProductsByEan(searchParam, page, perPage, headers, callback);
    case 'sku':
      return getProductsBySku(searchParam, page, perPage, headers, callback);
    case 'name':
    default:
      return getProductsByName(searchParam, page, perPage, headers, callback);
  }
};
