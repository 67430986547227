import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IProduct } from '@/Interfaces/Products'
import QuickProductAdd from '@/Components/QuickProductAdd/QuickProductAdd'
import { ProductSkeletonLoading } from '@/Helpers/skeletonLoading'

import { useFetchStore } from '@/Hooks/useFetchStore'
import { getProducts } from '@/Api/products'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

import { useEffectOnce } from 'react-use'

type ProductsProps = {
  category: any
}

const PAGE_SIZE = 10

export default function ProductsHorizontal(props: ProductsProps) {
  const { category } = props
  const [hasNext, setHasNext] = useState(false)

  const { getFetchDetails } = useFetchStore()
  const { headers, callback } = getFetchDetails()

  const scrollAmount = 200

  const { data, isLoading, isFetching, isError, status } = useQuery({
    queryKey: ['randomHome', category.link],
    queryFn: () => getProducts(category.link, 0, PAGE_SIZE, headers, callback),
  })

  const scrollDiv = (direction: 'left' | 'right', amount: number) => {
    const productsDiv = document.getElementById(
      `discover-${category.link}`
    ) as HTMLDivElement
    if (direction === 'left') {
      productsDiv.scrollBy({ left: -amount, behavior: 'smooth' })
    } else {
      productsDiv.scrollBy({ left: amount, behavior: 'smooth' })
    }
  }

  const isOverflowing = (div: HTMLDivElement, axis: 'x' | 'y') => {
    if (axis === 'x') {
      return !!(div.scrollWidth > div.clientWidth)
    } else {
      return !!(div.scrollHeight > div.clientHeight)
    }
  }

  useEffectOnce(() => {
    const productsDiv = document.getElementById(
      `discover-${category.link}`
    ) as HTMLDivElement
    if (isOverflowing(productsDiv, 'x')) {
      setHasNext(true)
    }
  })

  return (
    <div className="w-full h-full items-center lg:flex lg:justify-between lg:px-4">
      <div
        className={`hidden lg:flex h-full justify-center items-center cursor-pointer ${
          hasNext ? 'visible' : 'invisible'
        }`}
        onClick={() => scrollDiv('left', scrollAmount)}
      >
        <ChevronLeftIcon className="h-8 w-8 text-stone-700 dark:text-stone-200" />
      </div>
      <div
        id={`discover-${category.link}`}
        className="
          flex w-full auto-rows-min overflow-x-auto hide-scroll-bar
          whitespace-no-wrap gap-2 py-3 px-4 snap-x [&>div.card]:flex-none
          [&>div.card]:w-7/12 [&>div.card]:snap-center [&>div.card]:lg:w-4/12
          [&>div.card]:xl:w-3/12 [&>div.card]:2xl:w-1/5
          lg:gap-6 lg:px-0
        "
      >
        {isLoading || isFetching ? (
          <>
            {Array.from({ length: PAGE_SIZE }).map((_, index) => (
              <ProductSkeletonLoading key={index} />
            ))}
          </>
        ) : status === 'error' ? (
          <span>Error: {isError}</span>
        ) : (
          <>
            {data.result.map((item: IProduct) => (
              <QuickProductAdd item={item} key={item.id} />
            ))}
          </>
        )}
      </div>
      <div
        className={`hidden lg:flex h-full justify-center items-center cursor-pointer ${
          hasNext ? 'visible' : 'invisible'
        }`}
        onClick={() => scrollDiv('right', scrollAmount)}
      >
        <ChevronRightIcon className="h-8 w-8 text-stone-700 dark:text-stone-200" />
      </div>
    </div>
  )
}
