import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react'

import Cart from '@/Components/Cart/Cart'
import Search from '@/Components/Search/Search'
import FinalizeOrder from '@/Components/FinalizeOrder/FinalizeOrder'
import Home from '@/Components/Home/Home'
import Alert from '@/Components/Alert/Alert'
import Loading from '@/Components/Loading/Loading'
import CategoryList from '@/Components/CategoryList/CategoryList'
import CategoryView from '@/Components/Categories/CategoryView'
import Toast from '@/Components/Toast/Toast'
import Checkout from '@/Components/Checkout/Checkout'
import Account from '@/Components/Account/Account'

import SGLayout from '@vars/sg.json'
import CBBLayout from '@vars/CBB.json'
import CatupiryLayout from '@vars/catupiry.json'
import RioQualityLayout from '@vars/RIO_QUALITY.json'
import Prime from '@vars/PRIME.json'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useTokenStore } from '@/Hooks/useTokenStore'
import { useHeaderStore } from '@/Hooks/useHeaderStore'
import { useLoadingStore } from '@/Hooks/useLoadingStore'
import { useCategoryStore } from '@/Hooks/useCategoryStore'
import { useSessionStore } from '@/Hooks/useSessionStore'

import { CartContextProvider } from '@/Contexts/CartContext'

export default function App() {
  const { history, addHistory, getCurrentHistory } = useHistoryStore()
  const { tokenState, setToken, setFields, setCustomer } = useTokenStore()
  const { headers, setHeader } = useHeaderStore()
  const { setIsLoading } = useLoadingStore()
  const { categories, getCategories, activeCategory } = useCategoryStore()
  const { setSessionInfo } = useSessionStore()

  const endpoint = process.env.REACT_APP_AUTH_API

  const selectRefreshToken = (channel: string) => {
    const envVarName = `REACT_APP_REFRESH_TOKEN_${channel.toUpperCase()}`
    return process.env[envVarName] || process.env.REACT_APP_REFRESH_TOKEN_CBB
  }
  const token = window.location.pathname.substr(1)

  const verifyAccess = async () => {
    if (!token) return addHistory({ route: 'NoToken' })
    if (getCurrentHistory()!.route === 'Blocked') return
    addHistory({ route: 'Verifying' })
    const reqValues = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let parsedToken: any
    try {
      parsedToken = JSON.parse(window.atob(token))
    } catch (e: any) {
      return addHistory({ route: 'Blocked' })
    }
    setIsLoading(true)
    const channelRefresh = selectRefreshToken(parsedToken.cdChannel)
    await fetch(`${endpoint}/refresh`, {
      ...reqValues,
      body: JSON.stringify({ refreshToken: channelRefresh }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        parsedToken = { ...parsedToken, requestToken: data.token }
        return await fetch(`${endpoint}/verify`, {
          ...reqValues,
          body: JSON.stringify({ token: data.token }),
        })
      })
      .then((response) => response.json())
      .catch(() => addHistory({ route: 'Blocked' }))
      .finally(() => {
        setToken(parsedToken)
        switch (parsedToken.cdChannel) {
          case 'sg':
            setFields(JSON.stringify(SGLayout))
            break
          case 'CBB':
            setFields(JSON.stringify(CBBLayout))
            setCustomer(
              'https://prd-wdu-bot-customer-cbb-dot-prd-wdu-pedmais-001.uc.r.appspot.com'
            )
            break
          case 'RIO_QUALITY':
            setFields(JSON.stringify(RioQualityLayout))
            break
          case 'PRIME':
            setFields(JSON.stringify(Prime))
            break
          case 'catupiry':
            setFields(JSON.stringify(CatupiryLayout))
            break
          case 'weduu':
          default:
            setFields(JSON.stringify(CBBLayout))
            setCustomer(
              'https://prd-wdu-bot-customer-cbb-dot-prd-wdu-pedmais-001.uc.r.appspot.com'
            )
            break
        }
      })
  }

  const setUserEntered = async () => {
    await fetch(`${tokenState.callBack}/ecommerce/order`, {
      method: 'PATCH',
      headers: { ...headers },
      body: JSON.stringify({ flEnteredInFrontEnd: true }),
    })
      .then((response) => response)
      .catch((err: any) => addHistory({ route: 'Blocked' }))
  }

  const handleTheme = useCallback(() => {
    switch (tokenState.cdChannel) {
      case 'sg':
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'sg')
      case 'RIO_QUALITY':
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'rioquality')
      case 'CBB':
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'cbb')
      case 'catupiry':
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'catupiry')
      case 'PRIME':
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'prime')
      case 'weduu':
      default:
        return document
          .getElementsByTagName('html')[0]
          .setAttribute('data-theme', 'default')
    }
  }, [tokenState])

  const setHeaderValues = useCallback(() => {
    setHeader('Content-Type', 'application/json')
    setHeader('Accept', '*/*')
    setHeader('idSession', tokenState?.idSession)
    setHeader('Authorization', `Bearer ${tokenState?.requestToken}`)
  }, [setHeader, tokenState?.idSession, tokenState?.requestToken])

  useLayoutEffect(() => {
    if (!tokenState.requestToken) {
      verifyAccess()
    } else {
      setHeaderValues()
      handleTheme()
      setSessionInfo()
    }
  }, [tokenState])

  useEffect(() => {
    if (tokenState.requestToken) {
      Promise.all([getCategories(), setUserEntered()])
        .then(() => {
          setIsLoading(false)
          addHistory({ route: 'Home' })
        })
        .catch(() => addHistory({ route: 'Blocked' }))
    }
  }, [headers])

  useEffect(() => {
    if (
      tokenState &&
      tokenState.requestToken &&
      Object.keys(activeCategory).length !== 0
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      addHistory({ route: 'CategoryView' })
    }
  }, [activeCategory])

  function useRandomCategories(categoryList: string[]): string[] {
    const shuffled = useMemo(
      () => [...categoryList].sort(() => 0.5 - Math.random()),
      [categoryList]
    )
    return useMemo(
      () => shuffled.sort(() => 0.5 - Math.random()).slice(0, 3),
      [shuffled]
    )
  }

  const randomCategories = useRandomCategories(categories)

  const routes = (): ReactNode => {
    if (!history.length) {
      addHistory({ route: 'Verifying' })
    }
    const current = getCurrentHistory()!.route as string
    switch (current) {
      case 'NoToken':
        return (
          <Alert
            title="Token de acesso não encontrado"
            message="Você pode gerar um token falando com nosso chatbot no Whatsapp."
            icon="alert"
          />
        )
      case 'Blocked':
        return (
          <Alert
            title="Token de acesso inválido ou expirado"
            message="Você pode gerar um novo token falando com nosso chatbot no Whatsapp."
            icon="alert"
          />
        )
      case 'Verifying':
        return (
          <Alert
            title="Preparando catálogo..."
            message="Estamos verificando seu token de acesso. Por favor, aguarde."
            icon="loading"
          />
        )
      case 'LoadingData':
        return (
          <Alert
            title="Preparando catálogo..."
            message="Estamos carregando seus produtos."
            icon="loading"
          />
        )
      case 'CategoryList':
        return <CategoryList />
      case 'CategoryView':
        return <CategoryView />
      case 'Search':
      case 'Barcode':
        return <Search />
      case 'Cart':
        return <Cart />
      case 'Checkout':
        return <Checkout />
      case 'Finalize':
        return <FinalizeOrder />
      case 'Account':
        return <Account />
      case 'Home':
      default:
        return <Home randomCategories={randomCategories} />
    }
  }

  return (
    <CartContextProvider>
      <div className="App h-full fixed inset-0 overflow-hidden overscroll-none lg:relative lg:overflow-visible">
        {routes()}
        <Loading />
      </div>
      <Toast />
    </CartContextProvider>
  )
}
