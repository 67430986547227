import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { LoadingIndicator } from '@/Helpers/icons'

interface IAlerts {
  title: string
  message: string
  icon?: string
}

export default function Alert(props: IAlerts) {
  const iconHandler = () => {
    if (!props.icon) {
      return;
    }
    switch (props.icon) {
      case 'loading':
        return (
          <div className='rounded-full h-36 w-36 m-auto flex items-center'>
            <LoadingIndicator className='stroke-brand-500 dark:stroke-brand-400 h-32 w-32 animate-spin' />
          </div>
        )
      case 'alert':
        return (
          <div className='bg-brand-100 dark:bg-stone-800 dark:border-stone-800 rounded-full border-8 border-brand-100 h-36 w-36 m-auto flex items-center'>
            <ExclamationTriangleIcon className='h-32 w-32 stroke-brand-500 dark:stroke-brand-400' />
          </div>
        )
      default:
        return;
    }
  }

  return (
    <>
      <div className="relative z-50">
        <div className={`fixed inset-0 z-50 bg-stone-100 dark:bg-stone-900 overscroll-none h-screen touch-none alert-window show transition-opacity ease-in duration-700 opacity-100`} data-testid="alert-window" >
          <div className="details overscroll-none touch-none select-none h-full flex flex-col justify-center items-center">
            <div className="px-12 m-auto text-center align-middle">
              {iconHandler()}
              <h3 className="text-brand-800 dark:text-brand-300 font-semibold text-lg leading-7 mt-8">{props.title}</h3>
              <h5 className='text-brand-500 dark:text-stone-500 mt-1 text-sm font-medium leading-tight'>{props.message}</h5>
            </div>
          </div>
        </div>
        <div className={`backdrop visible h-screen absolute`}></div>
      </div>
    </>
  );
}
