import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'

import { ProductSkeletonLoading } from "@/Helpers/skeletonLoading";
import SuggestionProductsHorizontal from './SuggestionsProductsHorizontal';

import { useSessionStore } from '@/Hooks/useSessionStore';
import { getSuggestions } from "@/Api/products";
import { useFetchStore } from '@/Hooks/useFetchStore';

const PAGE_SIZE = 10;

export default function Suggestions(props: any) {
  const [hasSuggestions, setHasSuggestions] = useState(true);
  const { sessionInfo } = useSessionStore();
  const { getCustomerDetails } = useFetchStore();

  const {customer, headers} = getCustomerDetails();

  const {
    data: dataReposicao,
    isLoading: isLoadingReposicao,
    isFetching: isFetchingReposicao,
    isError: isErrorReposicao,
  } = useQuery({
    queryKey: ['homeSuggestions', 'REPOSICAO'],
    queryFn: () => getSuggestions(customer, sessionInfo.nrNationalRegistry, 'REPOSICAO', 0, PAGE_SIZE, headers),
    retry: (failureCount) => {
      if (failureCount > 0) return false;
      return true;
    },
  })

  const {
    data: dataSimilares,
    isLoading: isLoadingSimilares,
    isFetching: isFetchingSimilares,
    isError: isErrorSimilares,
  } = useQuery({
    queryKey: ['homeSuggestions', 'SIMILARES'],
    queryFn: () => getSuggestions(customer, sessionInfo.nrNationalRegistry, 'SIMILARES', 0, PAGE_SIZE, headers),
    retry: (failureCount) => {
      if (failureCount > 0) return false;
      return true;
    },
  })

  const {
    data: dataResgate,
    isLoading: isLoadingResgate,
    isFetching: isFetchingResgate,
    isError: isErrorResgate,
  } = useQuery({
    queryKey: ['homeSuggestions', 'RESGATE'],
    queryFn: () => getSuggestions(customer, sessionInfo.nrNationalRegistry, 'RESGATE', 0, PAGE_SIZE, headers),
    retry: (failureCount) => {
      if (failureCount > 0) return false;
      return true;
    },
  })

  const suggestionSkeleton = () => {
    return (
      <>
        <div className="px-4 pt-3 flex justify-between align-middle items-center text-xs grow">
          <p className="h-3 bg-stone-200 rounded-full dark:bg-stone-700 w-6/12 mb-1" />
        </div>
        <div className="flex w-full auto-rows-min overflow-x-auto hide-scroll-bar
            whitespace-no-wrap gap-2 py-3 px-4 snap-x [&>div.card]:flex-none
            [&>div.card]:w-7/12 [&>div.card]:snap-center [&>div.card]:lg:w-4/12
            [&>div.card]:xl:w-3/12 [&>div.card]:2xl:w-1/5
            lg:gap-6 lg:px-0">
          {Array.from({ length: PAGE_SIZE }).map((_, index) => (
            <ProductSkeletonLoading key={index} />
          ))}
        </div>
      </>
    )
  }
  useEffect(() => {
    if (
      (dataReposicao && dataReposicao.result.length === 0) &&
      (dataSimilares && dataSimilares.result.length === 0) &&
      (dataResgate && dataResgate.result.length === 0)
    ) {
      setHasSuggestions(false);
    }
  }, [dataReposicao, dataSimilares, dataResgate]);

  return (
    <>
      <div className={`${(hasSuggestions) ? '' : 'hidden'}`}>

        <h5 className='font-medium px-4 text-xl leading-7 mt-6 text-brand-500 dark:text-brand-400'>Sugestões para seu negócio</h5>
        <p className='text-xs px-4 font-normal leading-none mt-2 text-stone-600 dark:text-stone-400'>Selecionamos os melhores produtos para você!</p>
        <div className="mt-3 mb-16">

          { (isLoadingReposicao || isFetchingReposicao) ? (
            suggestionSkeleton()
          ) : (dataReposicao && dataReposicao.result.length > 0) ? (
            <SuggestionProductsHorizontal
              type='REPOSICAO'
              category={dataReposicao.result[0].attributes.suggestion.suggestedReason}
              productsArray={dataReposicao.result}
            />
          ) : (
            <></>
          )}

          { (isLoadingResgate || isFetchingResgate) ? (
            suggestionSkeleton()
          ) : (dataResgate && dataResgate.result.length > 0) ? (
            <SuggestionProductsHorizontal
              type='RESGATE'
              category={dataResgate.result[0].attributes.suggestion.suggestedReason}
              productsArray={dataResgate.result}
            />
          ) : (
            <></>
          )}

          { (isLoadingSimilares || isFetchingSimilares) ? (
            suggestionSkeleton()
          ) : (dataSimilares && dataSimilares.result.length > 0) ? (
            <SuggestionProductsHorizontal
              type='SIMILARES'
              category={dataSimilares.result[0].attributes.suggestion.suggestedReason}
              productsArray={dataSimilares.result}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
