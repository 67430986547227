import product_placeholder from '../Assets/images/product_placeholder.png'

import { useContext } from 'react'
import { CartContext } from '../Contexts/CartContext'

export const imageProcessor = (image: any, blob?: boolean) => {
  const regex = /\?dl=0/g
  if (!image) return product_placeholder
  if (regex.test(image)) return image.replace(regex, '?raw=1')
  return image
}

export const ImagePlacer = (item: any) => {
  const { brand, cdChannel } = useContext(CartContext)
  if (brand) {
    return (
      <img
        src={imageProcessor(item.image)}
        className="object-center object-contain"
        alt={item.name}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = `static/brands/${cdChannel}/${brand}.png`
          currentTarget.alt = item.name
        }}
      />
    )
  }
  return (
    <img
      src={imageProcessor(item.image)}
      className="m-auto object-contain"
      alt={item.name}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = product_placeholder
        currentTarget.alt = item.name
      }}
    />
  )
}
