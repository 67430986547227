import { useContext, useEffect, useMemo, useState } from 'react'
import CartItems from '@/Components/CartItems/CartItems'
import { CartContext } from '@/Contexts/CartContext'
import CheckoutButton from '@/Components/Checkout/CheckoutButton'
import LogoHandler from '@/Helpers/LogoHandler'
import HeaderLayout from '@/Layout/Header/Header'
import { EmptyCartIcon } from '@/Helpers/icons'
import ConfirmDialog from '@/Helpers/dialog'
import { TrashIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'

import { useHistoryStore } from '@/Hooks/useHistory'
import { useCartStore } from '@/Hooks/useCartStore'
import { ICart } from '@/Interfaces/Cart'
import { NumericFormat } from 'react-number-format'

export default function Cart() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const { updateCartData, layoutVars } = useContext(CartContext)

  const { goBack, goHome } = useHistoryStore()
  const {
    cartItems,
    cartResponse,
    getTotalItems,
    clearCart,
    getErrorItems,
    clearErrorItems,
  } = useCartStore()

  const totalItems = useMemo(() => {
    if (!cartItems) return 0
    return getTotalItems()
  }, [getTotalItems, cartItems])

  const errorItems = useMemo(() => {
    if (!cartResponse) return []
    return getErrorItems()
  }, [getErrorItems, cartResponse])

  useEffect(() => {
    updateCartData()
  }, [cartItems])

  const handleConfirm = () => {
    clearCart()
    setIsDialogOpen(false)
  }
  const handleRemoveErrorItems = () => {
    clearErrorItems()
    setIsErrorDialogOpen(false)
  }

  return (
    <>
      <HeaderLayout columns={3}>
        <div>
          <div
            className="hidden lg:flex items-start justify-start w-full max-h-11 max-w-24 lg:max-w-max lg:max-h-40 cursor-pointer"
            onClick={goHome}
          >
            <LogoHandler />
          </div>
          <div className="lg:hidden">
            <ChevronLeftIcon
              className="h-6 w-6 text-stone-700 dark:text-stone-200"
              onClick={goBack}
            />
          </div>
        </div>
        <h5 className="w-full flex-1 line-clamp-1 text-base leading-tight font-semibold text-stone-700 dark:text-stone-200">
          Carrinho
        </h5>
        <div>
          <div
            className="hidden lg:inline-flex items-center text-stone-700 dark:text-stone-200 text-xs cursor-pointer"
            onClick={() => setIsDialogOpen(true)}
          >
            Limpar carrinho{' '}
            <TrashIcon
              className={`ml-2 h-6 w-6  ${
                getTotalItems() <= 0 ? 'invisible' : ''
              }`}
            />
          </div>
          <div className="lg:hidden">
            <TrashIcon
              className={`h-6 w-6 text-stone-700 dark:text-stone-200 ${
                getTotalItems() <= 0 ? 'invisible' : ''
              }`}
              onClick={() => setIsDialogOpen(true)}
            />
          </div>
        </div>
      </HeaderLayout>
      <ConfirmDialog
        title="Limpar carrinho"
        message="Tem certeza que deseja limpar o carrinho?"
        confirmText="Limpar"
        onConfirm={handleConfirm}
        cancelText="Cancelar"
        onCancel={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        onOpen={() => null}
      />

      <div className="content h-full pt-16 pb-36 lg:container lg:mx-auto lg:flex">
        {totalItems <= 0 && (
          <div className="h-full lg:mx-auto">
            <div className="flex flex-col text-center items-center h-full justify-center">
              <div className="content">
                <EmptyCartIcon className="h-40 m-auto text-brand-400 fill-stone-200 stroke-brand-200 dark:fill-stone-800 dark:stroke-brand-800 dark:text-brand-400" />
                <h5 className="mt-4 text-brand-800 dark:text-brand-200 font-medium text-base leading-normal">
                  Seu carrinho está vazio
                </h5>
                <button
                  className="bg-cta text-white dark:bg-cta-light px-3 py-2 w-fit mt-4 m-auto rounded-lg text-base font-semibold leading-normal"
                  onClick={goBack}
                >
                  Adicionar itens
                </button>
              </div>
            </div>
          </div>
        )}
        {totalItems > 0 && (
          <>
            {layoutVars.cart.creditLimit && cartResponse.cart.creditLimit && (
              <div className="w-full h-14 px-4 pt-2 pb-4 mb-2 bg-stone-100 dark:bg-stone-800 flex-col justify-start items-start gap-2 inline-flex">
                <div className="self-stretch justify-between items-center gap-1 inline-flex">
                  <div className="text-brand-950 text-xs dark:text-brand-50 font-medium leading-none">
                    Limite disponível para compras:
                  </div>
                  <div className="text-brand-900 dark:text-brand-100 text-xs font-medium leading-none">
                    <NumericFormat
                      value={cartResponse.cart.creditLimit}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'R$ '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </div>
                </div>
                {layoutVars.cart.creditLimitPercentage &&
                  cartResponse.cart.creditLimitPercentage >= 0 && (
                    <div className="self-stretch w-full h-2 bg-stone-200 dark:bg-stone-700 rounded flex-col justify-center items-start flex overflow-hidden">
                      <div
                        className={`h-2 relative bg-brand-400 rounded`}
                        style={{
                          width: `${
                            cartResponse.cart.creditLimitPercentage.toFixed(2) *
                            100
                          }%`,
                        }}
                      />
                    </div>
                  )}
              </div>
            )}
            <div className="h-full overflow-y-auto lg:w-9/12">
              <div
                className={`cart-items mx-4 grid grid-cols-1 gap-y-4 ${
                  layoutVars.cart.creditLimit && cartResponse.cart.creditLimit
                    ? 'mb-28'
                    : 'mb-10'
                }`}
              >
                {errorItems.length > 0 ? (
                  <>
                    {errorItems
                      .filter((item: ICart) => item.error)
                      .map((item: ICart) => {
                        return <CartItems item={item} key={item.id} />
                      })}
                    <div
                      className="
                      rounded-lg bg-stone-700 text-red-700 dark:text-red-300
                      text-sm font-semibold leading-tight inline-flex items-center
                      justify-center py-2 border-b border-stone-700 cursor-pointer
                    "
                      onClick={() => setIsErrorDialogOpen(true)}
                    >
                      <TrashIcon className="h-6 w-6 mr-2" />
                      <div>Excluir produtos indisponíveis</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <ConfirmDialog
                  title="Excluir produtos indisponíveis"
                  message="Tem certeza que deseja excluir produtos indisponíveis?"
                  confirmText="Excluir"
                  onConfirm={handleRemoveErrorItems}
                  cancelText="Cancelar"
                  onCancel={() => setIsErrorDialogOpen(false)}
                  open={isErrorDialogOpen}
                  onOpen={() => null}
                />
                {cartResponse.cart.items
                  .filter((item: ICart) => !item.error)
                  .map((item: ICart) => (
                    <CartItems item={item} key={item.id} />
                  ))}
                <div
                  className="continue-shopping text-brand-600 dark:text-brand-400 text-sm font-semibold w-full leading-tight cursor-pointer lg:w-fit"
                  onClick={goBack}
                >
                  Adicionar itens
                </div>
              </div>
            </div>
          </>
        )}
        {totalItems > 0 && <CheckoutButton type="cart" />}
      </div>
    </>
  )
}
