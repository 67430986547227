import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import { ShoppingCartIcon as ShoppingCartIconSolid } from '@heroicons/react/24/solid'
import { useCartStore } from '@/Hooks/useCartStore'
import { useHistoryStore } from '@/Hooks/useHistory'

interface IHeaderProps {
  size: string
  text?: string
}

const CartButton: React.FC<IHeaderProps> = ({ size, text }) => {
  const { getTotalItems } = useCartStore()
  const { addHistory, checkCurrentHistory } = useHistoryStore()

  return (
    <div
      className="flex flex-col items-center text-xs text-center relative cursor-pointer"
      onClick={(ev) => addHistory({ route: 'Cart' })}
    >
      {checkCurrentHistory('Cart') ? (
        <ShoppingCartIconSolid className={`${size}`} />
      ) : (
        <ShoppingCartIcon className={`${size}`} />
      )}
      {text && text}
      {getTotalItems() > 0 && (
        <span className="absolute inline-flex items-center justify-center h-4 text-2xs px-1 text-white font-semibold leading-none bg-cta dark:bg-cta-light rounded-md -top-1 left-1/2">
          {getTotalItems()}
        </span>
      )}
    </div>
  )
}

export default CartButton
