import {
  useEffect,
  useLayoutEffect,
  useCallback,
  useContext,
  useState,
} from 'react'
import { NumericFormat } from 'react-number-format'
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  CubeIcon,
} from '@heroicons/react/24/outline'

import { IProduct } from '@/Interfaces/Products'
import { ICart } from '@/Interfaces/Cart'
import { CartContext } from '@/Contexts/CartContext'
import { ImagePlacer } from '@/Helpers/Image'
import ItemFlag from '@/Components/ItemFlag/ItemFlag'
import TagsLayout from '@/Layout/Tags/Tags'

import { useCartStore } from '@/Hooks/useCartStore'

interface IQuickAddProps {
  item: IProduct
}

export default function QuickProductAdd(props: IQuickAddProps) {
  const item = props.item as ICart
  const { addToCart, updateCart, removeFromCart, getOnCartItem } =
    useCartStore()

  const [quantity, setQuantity] = useState<any>(0)
  const [touched, setTouched] = useState(false)
  const [clearItem, setClearItem] = useState(false)
  const { sendAlertMessage, setDetailedItem, layoutVars, detailedItem } =
    useContext(CartContext)
  const onCart = !!getOnCartItem(item.id) ? getOnCartItem(item.id) : false
  const minimunQuantity = item.minimunQuantity ? item.minimunQuantity : 1
  const isSuggestion =
    item.attributes && item.attributes.suggestion ? true : false
  const suggestedQty =
    item.attributes &&
    item.attributes.suggestion &&
    item.attributes.suggestion.suggestedQty
      ? item.attributes.suggestion.suggestedQty
      : 1

  const suggestionObject =
    item.attributes && item.attributes.suggestion
      ? item.attributes.suggestion
      : {}

  const addProduct = (item: ICart) => {
    if (!item.stock) return
    setTouched(true)
    isSuggestion
      ? addToCart(item, suggestedQty)
      : addToCart(item, minimunQuantity)
  }

  const handleOnBlur = (ev: any) => {
    ev.preventDefault()
    if (clearItem) {
      setQuantity(0)
      ev.target.value = 0
      removeFromCart(item)
    }
    if (ev.target.value > 0) {
      if (item.minimunQuantity && ev.target.value < item.minimunQuantity) {
        sendAlertMessage({
          type: 'warning',
          message: `A quantidade mínima para o item ${item.name} é de: ${item.minimunQuantity}.`,
        })
        return setQuantity(parseFloat(item.minimunQuantity.toString()))
      }
    }
  }

  const handleOnFocus = (ev: any) => {
    ev.preventDefault()
    const end = ev.target.value.length
    ev.target.setSelectionRange(end, end)
    if (ev.target.value === '0') {
      ev.target.value = ''
    }
  }

  const handleOnChange = (ev: any, sourceInfo: any) => {
    setClearItem(false)
    if (sourceInfo.source === 'prop') return
    if (!ev.value) {
      setClearItem(true)
      setQuantity(null!)
      return
    }
    if (ev.value > parseFloat(item.stockQuantity)) {
      return updateCart(item, parseFloat(item.stockQuantity))
    }
    if (parseFloat(ev.value) >= 0) {
      return updateCart(item, parseFloat(ev.value))
    }
    return
  }

  const addQuantity = () => {
    setTouched(true)
    if (quantity < parseFloat(item.stockQuantity)) {
      addToCart(item, minimunQuantity)
    }
  }
  const reduceQuantity = () => {
    setTouched(true)
    return item.quantity - minimunQuantity > 0
      ? addToCart(item, -minimunQuantity)
      : removeFromCart(item)
  }

  useEffect(() => {
    if (onCart) {
      if (touched && item.quantity !== onCart.quantity) {
        if (item.quantity) {
          setQuantity(item.quantity)
        } else {
          setQuantity(onCart.quantity)
        }
      } else {
        setQuantity(item.quantity)
      }
    }
  }, [detailedItem])

  useEffect(() => {
    if (onCart && touched && quantity === 0) {
      removeFromCart(item)
    }
  }, [quantity])

  useLayoutEffect(() => {
    if (onCart) {
      setQuantity(onCart.quantity)
      item.quantity = onCart.quantity
    }
  }, [onCart])

  const handleClick = useCallback((e: any) => {
    e.preventDefault()
    if (!item.stock) return
    setTouched(true)
    if (quantity > 0) {
      if (item.minimunQuantity && quantity < item.minimunQuantity) {
        item.quantity = item.minimunQuantity
      }
    }
    setDetailedItem(item)
  }, [])

  const picking = () => {
    if (item.picking) {
      return (
        <div className="picking text-stone-500 dark:text-stone-400 mt-1 w-full inline-flex items-center">
          <CubeIcon className="h-5 w-5 stroke-1 mr-1" /> {item.picking}
        </div>
      )
    }
    return <></>
  }

  const renderPrice = () => {
    if (!layoutVars.products || !layoutVars.products.price) return
    if (item.price && item.listPrice > item.price) {
      return (
        <div className="price">
          <p className="price-group mb-0">
            <span className="old-price mt-1 text-stone-400 line-through">
              {item.listPriceLabel}
            </span>
            <span className="list-price mt-1">{item.priceLabel}</span>
          </p>
        </div>
      )
    }
    return (
      <div className="price">
        <span className="list-price mt-1">{item.listPriceLabel}</span>
      </div>
    )
  }

  const actionButton = () => {
    let text = 'Adicionar'

    if (!item.stock) {
      text = 'Indisponível'
    }
    if (!onCart) {
      return (
        <button
          className="rounded-lg bg-cta w-full h-9 text-sm text-white dark:bg-cta-light disabled:grayscale-60 disabled:text-brand-200"
          onClick={() => addProduct(item)}
        >
          {text}
        </button>
      )
    }
    return (
      <div className="quick-add-to-cart rounded-lg bg-stone-50 text-cta h-9 overflow-hidden ring-1 ring-inset ring-cta p-2 w-full dark:bg-stone-800 dark:ring-cta-light dark:text-cta-light">
        <div className="inline-flex items-center justify-center content-center h-5 w-full max-w-full">
          <button className="btn h-5 w-5" onClick={reduceQuantity}>
            {quantity <= 1 || quantity === minimunQuantity ? (
              <TrashIcon className="h-5 w-5" />
            ) : (
              <MinusIcon className="h-5 w-5" />
            )}
          </button>
          <NumericFormat
            value={quantity}
            displayType={'input'}
            allowNegative={false}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={
              layoutVars.products && layoutVars.products.decimalPlaces
                ? layoutVars.products.decimalPlaces
                : 2
            }
            readOnly={
              layoutVars.products && layoutVars.products.blockQuantityInput
                ? layoutVars.products.blockQuantityInput
                : false
            }
            suffix={
              layoutVars.products && layoutVars.products.pickingOnQty
                ? ` ${item.picking}`
                : ''
            }
            inputMode="decimal"
            className="text-sm text-center outline-none font-semibold bg-stone-50 grow h-5 w-2/3 align-middle indent-0 dark:bg-stone-800"
            onValueChange={handleOnChange}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
          <button className="btn h-5 w-5 text-right" onClick={addQuantity}>
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    )
  }

  const attributes = () => {
    if (!item.attributes) return
    const attributesArray = Object.keys(item.attributes).map((key) => {
      return { type: key, ...item.attributes[key] }
    })

    return attributesArray.map((attribute) => {
      return (
        <ItemFlag
          key={attribute.type}
          type={attribute.type}
          name={attribute.name}
          description={attribute.description}
          valueLabel={attribute.valueLabel}
          exp={attribute.exp}
          iat={attribute.iat}
        />
      )
    })
  }

  return (
    <div className="card bg-stone-50 shadow-md dark:bg-stone-800 shadow-stone-950/10 rounded-xl p-2 flex flex-col items-stretch lg:h-full flex-1 lg:p-4 lg:cursor-pointer relative">
      {layoutVars.catalog &&
        layoutVars.catalog.suggestion &&
        Object.keys(suggestionObject).length > 0 && (
          <div className="absolute left-2 top-2">
            <TagsLayout
              label={suggestionObject.suggestedReason}
              type="icon"
              icon={suggestionObject.suggestedType}
            />
          </div>
        )}
      <div
        className="product-image w-full h-23 overflow-hidden [&>img]:max-h-full flex items-center justify-center"
        onClick={handleClick}
      >
        <ImagePlacer image={item.image} name={item.name} />
      </div>
      <div
        className="product-title text-xs grow mt-2 min-h-8"
        onClick={handleClick}
      >
        <p className="text-stone-700 dark:text-stone-50 font-medium line-clamp-2">
          {item.name}
        </p>
      </div>
      <div className="product-info text-xs text-stone-500 dark:text-stone-400">
        {layoutVars && layoutVars.products && layoutVars.products.ean && (
          <p className="mt-1">EAN: {item.cdEan}</p>
        )}
        {layoutVars && layoutVars.products && layoutVars.products.sku && (
          <p className="mt-1">SKU: {item.cdSku}</p>
        )}
        {picking()}
      </div>
      <div className="product-price mb-1 font-semibold text-sm text-brand-600 dark:text-brand-400">
        {renderPrice()}
      </div>
      <div className="product-button mt-1">{actionButton()}</div>
    </div>
  )
}
