import { NumericFormat } from 'react-number-format';
import { uniqueId } from "lodash";
import { useCartStore } from '@/Hooks/useCartStore';

enum TotalTypes {
  currency = "currency",
  percentage = "percentage",
  number = "number",
  weight = "weight"
}

export default function TotalizerDetails() {
  const { cartResponse } = useCartStore();

  const printValueByType = (type: TotalTypes, value: number) => {
    switch (type) {
      case TotalTypes.currency:
        return <NumericFormat value={value} displayType={'text'} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," decimalScale={2} prefix={'R$ '} />;
      case TotalTypes.percentage:
        return <NumericFormat value={value} displayType={'text'} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={'%'} />;
      case TotalTypes.weight:
        return <NumericFormat value={value} displayType={'text'} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," decimalScale={2} suffix={'Kg'} />;
      case TotalTypes.number:
      default:
        return <NumericFormat value={value} displayType={'text'} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," decimalScale={2} />;
    }
  }

  const printTotalizerFields = () => {
    if (!cartResponse.cart.totalizer) return;
    const totalizerArray = Object.keys(cartResponse.cart.totalizer).map((key) => cartResponse.cart.totalizer[key]);
    return totalizerArray.map((item: any) => {
      return (
        <div className="w-full h-9 inline-flex justify-between items-center" key={uniqueId()}>
          <span>{item.name}</span>
          <span>{printValueByType(item.type, item.value)}</span>
        </div>
      )
    });
  }

  return (
    <>
      { printTotalizerFields() }
    </>
  );
}
